import { Flex, Switch, Text } from "@radix-ui/themes"
import { Responsive } from "@radix-ui/themes/dist/cjs/props"
import { ComponentPropsWithoutRef } from "react"
import { RequireKeys } from "../../types/utils"

interface ToggleProps
  extends RequireKeys<
      ComponentPropsWithoutRef<typeof Switch>,
      "checked" | "onCheckedChange"
    >,
    Pick<ComponentPropsWithoutRef<typeof Flex>, "gap"> {
  label: string
  sub?: string
  fullWidth?: boolean
}

// TODO: wat am i doing lol
function increaseSize(
  size: Responsive<"1" | "2" | "3"> | undefined
): Responsive<"1" | "2" | "3"> | undefined {
  if (!size) return

  if (typeof size === "string") {
    return (Number.parseInt(size) + 1).toString() as Responsive<"1" | "2" | "3">
  }

  return Object.fromEntries(
    Object.entries(size).map(([key, value]) => [
      key,
      (Number.parseInt(value) + 1).toString(),
    ])
  )
}

function Toggle({ label, sub, fullWidth, gap, ...props }: ToggleProps) {
  return (
    <Text as="label" size={increaseSize(props.size) ?? "3"}>
      <Flex
        gap={gap ?? "2"}
        direction={fullWidth ? "row-reverse" : "row"}
        justify={fullWidth ? "between" : "start"}
        width={fullWidth ? "100%" : undefined}
      >
        <Switch size="2" {...props} />{" "}
        <Flex direction="column" gap="1">
          <Text>{label}</Text>{" "}
          {sub && (
            <Text color="gray" size="2">
              {sub}
            </Text>
          )}
        </Flex>
      </Flex>
    </Text>
  )
}

export default Toggle
