import { TabsTrigger } from "@radix-ui/react-tabs"
import styled from "styled-components"

export const SettingsCategory = styled.div`
  h3 {
    font-family: "JustSansRegular", sans-serif;
    font-size: 0.75rem;
    color: var(--chatrpg-logo-color); /* Replace with your desired color */
    margin-top: 10px; /* Adjust top margin to align with list items */
    margin-bottom: 15px; /* Adjust bottom margin for spacing */
    margin-left: 0px;
  }
`

export const SettingsButton = styled(TabsTrigger)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;

  color: #97bac1;
  font-family: "SF", sans-serif;
  padding: 8px 10px; /* Maintain existing top and bottom padding */
  margin: 2px 0px; /* Adjusted margin for spacing */
  border: none;
  text-align: left;
  transition: background-color 0.3s, color 0.3s, border-radius 0.3s; /* Added border-radius transition */

  svg {
    font-size: 1.25rem;
  }

  svg.flipped {
    transform: scaleX(-1);
  }

  span {
    padding-top: 1px;
  }

  &[data-state="active"] {
    border-radius: 5px;
    background-color: #505050; /* Active button background color */
    color: #ffffff; /* Active button text color */
  }

  &:hover {
    background-color: #707070;
    color: #d3d3d3; /* Text color changed to white on hover */
    border-radius: 5px; /* Rounded corners on hover */
  }
`
