import { AspectRatio, Button, Flex, ScrollArea, Text } from "@radix-ui/themes"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { styled } from "styled-components"
import { RootState } from "../../../store"
import { toggleFullscreenMode } from "../../../store/reducers/fullScreenReducer"
import { toggleTheatreMode } from "../../../store/reducers/theatreModeReducer"
import { useUserProfile } from "../../contexts/UserProfileContext"
import CustomPanel from "./custompanel/CustomPanel"
import InfoBox from "./description/InfoBox"
import EmbedPlatformSelector from "./embed/EmbedPlatformSelector"
import ShimmerPlayer from "./embed/ShimmerPlayer"

function PlayerArea() {
  const dispatch = useDispatch()

  const { profile } = useUserProfile()
  const isAdmin = profile?.administrator; // Replace this with the correct check for admin status


  const { isTheatreMode, isFullscreenMode } = useSelector(
    ({ theatreMode, fullscreenMode }: RootState) => ({
      isTheatreMode: theatreMode.isTheatreMode,
      isFullscreenMode: fullscreenMode.isFullscreenMode,
    })
  )

  const getAspectRatio = () => {
    const playerWrapper = document.querySelector<HTMLElement>("#player")

    if (!playerWrapper) return 1

    let width = playerWrapper.offsetWidth
    let height = (width / 16) * 9

    const maxHeight = window.innerHeight - 215
    if (height > maxHeight) {
      height = maxHeight
    }

    const aspectRatio = width / height

    return aspectRatio
  }

  const shouldShowEmbed = !profile?.settings?.disableEmbeds

  const [aspectRatio, setAspectRatio] = useState(getAspectRatio)

  useEffect(() => {
    function handleResize() {
      setAspectRatio(getAspectRatio())
    }

    handleResize()
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  // TODO: Theatre and Fullscreen mode should never unmount other comps!
  return (
    <Wrapper m={isTheatreMode || isFullscreenMode ? "0" : "4"} mb="0">
      <Flex
        id="player"
        position="relative"
        // direction="column"
        // align="center"
        justify="center"
        height={isTheatreMode || isFullscreenMode ? "100%" : undefined}
        // style={isAdmin ? { border: '5px solid yellow' } : {}} // Apply yellow border only if admin
      >
        <AspectRatio ratio={aspectRatio}>
          <ShimmerPlayer />
        </AspectRatio>

        <EmbedControllers top="5" justify="center" gapX="3">
          <Button
            // size="3"
            onClick={() => dispatch(toggleTheatreMode())}
          >
            <Text weight="bold">
              Turn Theatre {isTheatreMode ? "OFF" : "ON"}
            </Text>
          </Button>

          <Button
            // size="3"
            onClick={() => dispatch(toggleFullscreenMode())}
          >
            <Text weight="bold">
              Toggle Full-Screen {isFullscreenMode ? "OFF" : "ON"}
            </Text>
          </Button>
        </EmbedControllers>

        {shouldShowEmbed && (
          <EmbedControllers bottom="4">
            <EmbedPlatformSelector />
          </EmbedControllers>
        )}
      </Flex>

      {!isTheatreMode && !isFullscreenMode && (
        <>
          <InfoBox />
          <CustomPanel />
        </>
      )}
    </Wrapper>
  )
}

const EmbedControllers = styled(Flex)`
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  z-index: 99;
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease;
`

const Wrapper = styled(ScrollArea)`
  /* TODO: Why do we need this again? */
  height: unset;

  & #player:hover ${EmbedControllers} {
    opacity: 1;
  }

  .rt-ScrollAreaScrollbar {
    visibility: hidden;
  }
`

export default PlayerArea
