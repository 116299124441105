import { css, styled } from "styled-components"
import { media } from "../styled.utils"

interface ContainerProps {
  $relative?: boolean
}

const Container = styled.div<ContainerProps>`
  max-width: 40rem;
  width: 100%;
  margin: 0 auto;
  padding: var(--space-m) var(--space-s);

  ${({ $relative }) =>
    $relative &&
    css`
      position: relative;
    `}

  ${media.md`
    padding: 0; 
  `}

  ${media.lg`
    padding: var(--space-l) var(--space-s);
    max-width: 64rem;
  `}

  ${media.xl`
    padding: var(--space-l) var(--space-3xs);
    max-width: 72rem;
  `}
`

export default Container
