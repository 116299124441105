import { Flex, Grid, Heading, IconButton, Text } from "@radix-ui/themes"
import { useEffect, useState } from "react"
import { FaRegTrashCan } from "react-icons/fa6"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../../../../store"
import { Extension } from "../../../../../../store/reducers/userDeveloperProfileReducer"
import {
  setUserStreamerProfile,
  UserStreamerProfile,
} from "../../../../../../store/reducers/userStreamerProfileReducer"
import { useToken } from "../../../../../contexts/TokenContext"
import {
  disableExtension,
  enableExtension,
  uninstallExtension,
} from "../../../../../extensions/api/Extensions"
import AlertDialogButton from "../../../../../ui/AlertDialogButton"
import { fetchMyExtensions as fetchInstalledExtensions } from "../api/fetchMyExtensions"
import ExtensionCard, { ExtensionCardButton } from "../components/ExtensionCard"

export interface ExtensionWithAuthor extends Extension {
  chatrpg_id: string
  chatrpg_username: string
}

function InstalledExtensions() {
  const [extensions, setExtensions] = useState<ExtensionWithAuthor[]>([])
  const dispatch = useDispatch()
  const { token } = useToken()
  const userStreamerProfile = useSelector(
    (root: RootState) => root.userStreamerProfile as UserStreamerProfile
  )

  useEffect(() => {
    fetchInstalledExtensions(token, dispatch, setExtensions)
  }, [token, dispatch])

  const isEnabled = (clientId: string) =>
    userStreamerProfile?.extensions?.find(
      (installedExtension) => installedExtension.clientId === clientId
    )?.enabled ?? false

  const handleExtensionAction = async (clientId: string) => {
    dispatch({ type: "START_LOADING" })
    try {
      const updatedUserStreamerProfile = isEnabled(clientId)
        ? await disableExtension(clientId, token)
        : await enableExtension(clientId, token)

      dispatch(setUserStreamerProfile(updatedUserStreamerProfile))
    } catch (error) {
      console.error(error)
    } finally {
      dispatch({ type: "STOP_LOADING" })
    }
  }

  const handleConfirm = async (clientId: string) => {
    dispatch({ type: "START_LOADING" })
    try {
      const updatedStreamerProfile = await uninstallExtension(clientId, token)
      console.log(updatedStreamerProfile)

      dispatch(setUserStreamerProfile(updatedStreamerProfile))
      setExtensions((prev) =>
        prev.filter((extension) => clientId !== extension.clientId)
      )
    } catch (error) {
      console.error(
        "There was an error in uninstalling extension:",
        { clientId },
        error
      )
    } finally {
      dispatch({ type: "STOP_LOADING" })
    }
  }

  return (
    <div>
      <Heading as="h1" mb="5">
        Installed Extensions
      </Heading>

      {userStreamerProfile?.extensions?.length === 0 ? (
        <Flex p="4" align="center" justify="center" gap="1" minHeight="80vh">
          <Text color="gray">Such empty...</Text>{" "}
        </Flex>
      ) : (
        <Grid
          columns="repeat(auto-fill, minmax(16rem, 1fr))"
          rows="repeat(auto-fill, minmax(24rem, 1fr))"
          gap="3"
        >
          {extensions.map((extension) => (
            <ExtensionCard
              key={extension._id}
              imageUrl="https://images.unsplash.com/photo-1617050318658-a9a3175e34cb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=600&q=80"
              author={extension.chatrpg_username}
              name={extension.extensionName}
              description={extension.description}
              metadata={extension}
              actionButtons={
                <>
                  <ExtensionCardButton
                    size="2"
                    color={isEnabled(extension.clientId) ? "orange" : "grass"}
                    onClick={() => handleExtensionAction(extension.clientId)}
                  >
                    {isEnabled(extension.clientId) ? "Disable" : "Enable"}
                  </ExtensionCardButton>

                  <AlertDialogButton
                    title="Uninstall Extension"
                    description="Are you sure? This extension will no longer be available to all your viewers."
                    warning="This action cannot be undone."
                    trigger={
                      <IconButton size="2" color="red">
                        <FaRegTrashCan />
                      </IconButton>
                    }
                    onConfirm={() => handleConfirm(extension.clientId)}
                  />
                </>
              }
            />
          ))}
        </Grid>
      )}
    </div>
  )
}

export default InstalledExtensions
