import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { css, styled } from "styled-components"
import { ITopicOptions } from '../../../types/ITopicOptions';
import { IAlertVisibilityContextAlert, useAlertVisibility } from '../../contexts/AlertVisibilityContext';
import { UpdateIcon } from '@radix-ui/react-icons';
import { useToken } from '../../contexts/TokenContext';

const AlertList: React.FC = () => {
    const { isCollapsed, alerts } = useAlertVisibility(); // Retrieve alerts from context
    const alertListRef = useRef<HTMLDivElement>(null); // Create a ref for the container
    const { token } = useToken()

    useEffect(() => {
        if (alertListRef.current) {
            // Scroll to the top of the container
            alertListRef.current.scrollTop = alertListRef.current.scrollHeight;
            // alertListRef.current.scrollTop = 0;
        }
    }, [alerts]); // Trigger this effect whenever alerts change


    const humanizeTopic = (topicKey: keyof ITopicOptions): string => {
        // Split the key at each uppercase letter, ensuring the first part is lowercased
        return topicKey
            // Convert camelCase to spaces and ensure each word starts with an uppercase letter
            .replace(/([A-Z])/g, ' $1')
            .trim()
            .split(/\s+/)
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
    }

    const handleResendAlert = async (alert: IAlertVisibilityContextAlert) => {
        try {   
            const { _id, ...alertWithoutId } = alert; 
            const response = await axios.post(
                `${process.env.REACT_APP_WEBSOCKET_SERVER_URL}/streamer/resendalert`,
                { ...alertWithoutId }, // This spreads all properties of the alert object
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
    
            console.log("Alert resent successfully:", response.data);
        } catch (error) {
            console.error("Failed to resend alert:", error.response?.data || error.message);
        }
    };

    return (
        <>
            {!isCollapsed && (
                <AlertListContainer ref={alertListRef}>
                    {alerts.map(event => (
                        <AlertItem key={event._id}>
                            <AlertHeader>
                                <AlertTitle>
                                    <ResendButton onClick={() => handleResendAlert(event)}>
                                        <StyledUpdateIcon />
                                    </ResendButton>
                                    <span>{humanizeTopic(event.topic as keyof ITopicOptions)} {event.data.amount && ` (${event.data.amount})`}</span>
                                </AlertTitle>
                                <AlertDate>{new Date(event.timestamp).toLocaleString()}</AlertDate>
                            </AlertHeader>
                            <AlertContent>
                                <AlertUsername>{event.data.username}:</AlertUsername>
                                {event.data.message && <AlertMessage>{`${event.data.message}`}</AlertMessage>}
                            </AlertContent>
                        </AlertItem>
                    ))}
                </AlertListContainer>
            )}
        </>
    );
};

const StyledUpdateIcon = styled(UpdateIcon)`
    width: 18px;
    height: 18px;
    stroke-width: 5; // Adjust this value to make the icon thicker
`;


const ResendButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin-right: 8px; 
    display: flex;
    align-items: center;
    color: #4A90E2;

    &:hover {
        color: #1F78D1;
    }

    &:focus {
        outline: none;
    }
`;

const AlertListContainer = styled.div`
    display: flex;
    flex-direction: column-reverse;
    gap: 5px;
    overflow-y: auto;
    height: 170px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
`;

const AlertItem = styled.div`
gap: 5px;
    background-color: #68686829;
    border-radius: 4px;
    word-wrap: break-word;
    white-space: normal;
    overflow: visible;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 8px;
    padding-bottom: 8px;
    display: flex;
    flex-direction: column;
`;

const AlertHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
`;

const AlertTitle = styled.strong`
    display: flex;
    font-size: 1rem;
    color: #4A90E2; // A soft blue
    font-weight: 600;
    align-items: center;
`;

const AlertContent = styled.div`
    display: flex;
    align-items: center; // Ensures vertical center alignment
    line-height: 1.2; // Normalizes line height for all children
`;

const AlertUsername = styled.span`
    font-weight: bold;
    color: #f8b426; // A vibrant pink
    font-size: 0.9rem;
    margin-right: 8px; // Space between username and message
    line-height: 1.2; // Ensures the line height matches EventContent
    /* vertical-align: middle; // Aligns text vertically */
`;

const AlertMessage = styled.span`
    font-size: 0.9rem;
    color: #FFF; // White color for contrast
    line-height: 1.2; // Matches the line height of EventUsername
    /* vertical-align: middle; // Aligns text vertically */
`;

const AlertDate = styled.small`
    font-size: 0.75rem;
    color: #BDBDBD; // Grey for a subtle look
`;

export default AlertList;
