import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { isMobileDevice } from '../../utils/isMobileDevice'; // Adjust the import path as needed

interface OrientationContextType {
  isLandscape: boolean;
}

const OrientationContext = createContext<OrientationContextType | undefined>(undefined);

export const useOrientation = (): OrientationContextType => {
  const context = useContext(OrientationContext);
  if (context === undefined) {
    throw new Error('useOrientation must be used within an OrientationProvider');
  }
  return context;
};

interface OrientationProviderProps {
  children: ReactNode;
}

export const OrientationProvider: React.FC<OrientationProviderProps> = ({ children }) => {
  const [isLandscape, setIsLandscape] = useState(isMobileDevice() && window.innerWidth > window.innerHeight);

  useEffect(() => {
    const handleOrientationChange = () => {
      setIsLandscape(isMobileDevice() && window.innerWidth > window.innerHeight);
    };

    window.addEventListener('resize', handleOrientationChange);
    window.addEventListener('orientationchange', handleOrientationChange);

    return () => {
      window.removeEventListener('resize', handleOrientationChange);
      window.removeEventListener('orientationchange', handleOrientationChange);
    };
  }, []);

  return (
    <OrientationContext.Provider value={{ isLandscape }}>
      {children}
    </OrientationContext.Provider>
  );
};
