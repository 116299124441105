import { Flex, Grid, Heading, Text } from "@radix-ui/themes"
import axios from "axios"
import { useDispatch } from "react-redux"
import { toggleSettings } from "../../../../../../store/reducers/settingsReducer" // Update the path as needed
import { authHandler } from "../../../../../auth/login/AuthHandler"
import PLATFORMS, { PlatformKey } from "../../../../../config/platformConfig"
import { useToken } from "../../../../../contexts/TokenContext"
import { useUserProfile } from "../../../../../contexts/UserProfileContext"
import ActionButton from "../../../../../ui/ActionButton"
import AlertDialogButton from "../../../../../ui/AlertDialogButton"
import LinkablePlatform from "../../../LinkablePlatform"
import { LinkContainer } from "./AccountLinkingOptionsStyles"
import AccountUpgradesSection from "./AccountUpgradesSection"
import StreamerAccountLinkingOptions from "../../streamersettings/StreamerAccountLinkingOptions"

const linkablePlatforms = Object.entries(PLATFORMS).filter(
  ([_, details]) => details.trueLink && !details.streamerOnly
)

function AccountLinkingOptions() {
  const dispatch = useDispatch()

  const { token, setToken } = useToken()
  const { profile, setProfile } = useUserProfile()

  const isDeletable = profile && !profile.isStreamer && !profile.isDeveloper

  const isLinked = (platform: PlatformKey) =>
    !!profile?.linkedAccounts?.[platform]

  const handleDeleteAccount = () => {
    if (!isDeletable) return

    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/profile/deleteaccount`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        setToken(null)
        setProfile(null)
        localStorage.removeItem("userProfile")
        localStorage.removeItem("token")
        console.log("Account successfully deleted.")
        dispatch(toggleSettings())
      })
      .catch((error) => {
        // Handle any errors, such as displaying an error message
        console.error("There was a problem deleting your account:", error)
      })
  }

  const getButtonLabel = (platform: PlatformKey) => {
    const { platformUsername } = profile?.linkedAccounts?.[platform] ?? {}

    const text = isLinked(platform)
      ? `${platform} Chatter Linked (${platformUsername})`
      : `Link your ${platform}`

    return (
      <Text
        size={{ initial: "2", sm: "3" }}
        weight="light"
        style={{ color: "white" }}
      >
        {text}
      </Text>
    )
  }

  return (
    <Flex direction="column" gap="5">
      <Flex direction="row" gap="5"> {/* This row will hold Linked Accounts and Account Upgrades side by side */}
        <LinkContainer size="3">
          <Heading as="h1" size="5" weight="medium" mb="4">
            Linked Accounts
          </Heading>
  
          <Grid columns={{ initial: "1", lg: !isDeletable ? "2" : "1" }} gap="4">
            {linkablePlatforms.map(([platform, details]) => (
              <LinkablePlatform
                key={platform}
                platform={platform}
                details={details}
                buttonLabel={getButtonLabel(platform)}
                isLinked={isLinked(platform)}
                isUnlinkable={
                  platform === "Twitch" && !isLinked("Youtube")
                    ? "Cannot remove Twitch without a linked YouTube account. In the future with more login methods we can resolve this. For now, you must delete your account."
                    : undefined
                }
                onOauthLink={() => {
                  authHandler(platform, "link", token, window.location.pathname)
                }}
              />
            ))}
          </Grid>
  
          <Flex justify="center" width="100%" mt="5">
            <AlertDialogButton
              title="Delete Account"
              description="Are you sure you want to delete your account?"
              warning="This action cannot be undone."
              trigger={
                <ActionButton
                  color="red"
                  disabled={{
                    status: !isDeletable,
                    content:
                      "You're a streamer or developer, you must first remove your Streamer AND Developer status to permanently delete your account.",
                  }}
                >
                  Delete Your Account
                </ActionButton>
              }
              onConfirm={handleDeleteAccount}
            />
          </Flex>
        </LinkContainer>
  
        {/* {(!profile?.isStreamer || !profile.isDeveloper) && ( */}
        {(!profile?.isStreamer) && (
          <AccountUpgradesSection />
        )}
      </Flex>
  
      {/* Place StreamerAccountLinkingOptions below the row */}
      {profile?.isStreamer && <StreamerAccountLinkingOptions />}
    </Flex>
  )
  
}

export default AccountLinkingOptions
