import { useEffect, useState } from "react"

const LIMIT = 500
const CONSECUTIVE_LIMIT = 6

const useCharacterLimit = (value: string) => {
  const [inputValue, setInputValue] = useState(value)
  const [isLimitReached, setIsLimitReached] = useState(false)
  const [isConsecutiveLimitReached, setIsConsecutiveLimitReached] =
    useState(false)

  const checkLimits = (value: string) => {
    // Check total character limit
    setIsLimitReached(value.length > LIMIT)

    // Check consecutive character limit
    const consecutiveCharsRegex = new RegExp(
      `(\\w)\\1{${CONSECUTIVE_LIMIT - 1},}`
    )

    setIsConsecutiveLimitReached(consecutiveCharsRegex.test(value))
  }

  useEffect(() => {
    checkLimits(value)
    setInputValue(value)
  }, [value])

  return {
    inputValue,
    isLimitReached,
    isConsecutiveLimitReached,
  }
}

export default useCharacterLimit
