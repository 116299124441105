import React, { useState, useEffect } from 'react';
import './PlatformIcons.css';
import PLATFORMS from '../../../../../config/platformConfig';
import { useToken } from '../../../../../contexts/TokenContext';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';
import { UserStreamerProfile, setUserStreamerProfile } from '../../../../../../store/reducers/userStreamerProfileReducer';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import SaveButton from '../../../../../ui/button/SaveButton';
import PlatformBarIcons from './PlatformBarIcons';


interface PlatformBarData {
    id: string; // Unique identifier for each platform bar
    platform: keyof typeof PLATFORMS;
    username: string;
}

const PlatformIcons = () => {
    const userStreamerProfile = useSelector((state: RootState) => state.userStreamerProfile) as UserStreamerProfile;

    const defaultPlatformBars = userStreamerProfile?.aboutMePlatformIcons?.map(player => ({
        id: `platform-bar-${player.platform}-${Math.random().toString(16).slice(2)}`, // Generate a unique ID
        platform: player.platform as keyof typeof PLATFORMS,
        username: player.username
    })) || [];

    console.log("defaultPlatformBars:", defaultPlatformBars);
    
    const [platformBars, setPlatformBars] = useState<PlatformBarData[]>(defaultPlatformBars);
    const { token } = useToken();
    const dispatch = useDispatch();
    const [isChanged, setIsChanged] = useState(false);

    const arraysEqual = (platformBars: PlatformBarData[], embeddedPlayers: any[]) => {
        console.log("platformBars BEING COMPAREd:", platformBars);
        console.log("embeddedPlayers BEING COMPARED:", embeddedPlayers);

        if (platformBars.length !== embeddedPlayers.length) {
            console.log("Lengths do not match.");
            return false;
        }

        for (let i = 0; i < platformBars.length; i++) {
            if (
                platformBars[i].platform !== embeddedPlayers[i].platform ||
                platformBars[i].username !== embeddedPlayers[i].username
            ) {
                console.log("Mismatch found at index", i);
                return false;
            }
        }
        console.log("Arrays are equal.");
        return true;
    };

    useEffect(() => {
        const aboutMePlatformIcons = userStreamerProfile?.aboutMePlatformIcons?.map(player => ({
            platform: player.platform as keyof typeof PLATFORMS,
            username: player.username
        })) || [];

        const aboutMePlatformsWithId = aboutMePlatformIcons.map((player, index) => ({
            ...player,
            id: `platform-bar-${index}` // Assign a unique id
        }));

        console.log("useEffect triggered. embeddedPlayers:", aboutMePlatformIcons, "current platformBars:", platformBars);

        if (!arraysEqual(platformBars, aboutMePlatformsWithId)) {
            console.log("Setting platformBars to match embeddedPlayers");
            setPlatformBars(aboutMePlatformsWithId);
        }
    }, [userStreamerProfile]);


    useEffect(() => {
        console.log("platformBars updated:", platformBars);
    }, [platformBars]);


    const handleAddPlatform = () => {
        if (platformBars.length >= 6) {
            alert('You can only add up to 6 platforms.');
            return;
        }

        const embeddablePlatforms = Object.keys(PLATFORMS).filter((key): key is keyof typeof PLATFORMS => PLATFORMS[key as keyof typeof PLATFORMS].embeddable);

        const defaultPlatform = embeddablePlatforms[0];

        const newId = `platform-bar-${platformBars.length}`; // Example id generation
        setPlatformBars(prevBars => {
            const newBars = [...prevBars, { id: newId, platform: defaultPlatform, username: '' }];
            setIsChanged(!arraysEqual(newBars, defaultPlatformBars)); // Check if arrays are equal
            return newBars;
        });
    };

    const handleRemovePlatform = (index: number) => {
        setPlatformBars(prevBars => {
            const newBars = prevBars.filter((_, i) => i !== index);
            setIsChanged(!arraysEqual(newBars, defaultPlatformBars)); // Check if arrays are equal
            return newBars;
        });
    };

    const handleSave = async () => {
        dispatch({ type: 'START_LOADING' });
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/streamer/options/aboutMePlatformIcons`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`, // Include the token here
                },
                body: JSON.stringify(platformBars),
                credentials: 'include', // if you're using sessions
            });

            if (response.ok) {
                const updatedStreamerProfile = await response.json();
                dispatch(setUserStreamerProfile(updatedStreamerProfile));
                console.log('Platform configurations saved and profile updated successfully');
                setIsChanged(false);
            } else {
                console.error('Failed to save platform configurations');
            }
        } catch (error) {
            console.error('Error:', error);
        }
        dispatch({ type: 'STOP_LOADING' });
        console.log('Saving platform bars:', platformBars);
    };

    const handlePlatformBarUpdate = (index: number, platform: keyof typeof PLATFORMS, username: string) => {
        setPlatformBars(prevBars => {
            const newBars = prevBars.map((bar, i) =>
                i === index ? { ...bar, platform, username: username } : bar
            );

            // Update isChanged based on comparison with defaultPlatformBars
            setIsChanged(!arraysEqual(newBars, defaultPlatformBars));

            return newBars;
        });
    };

    const onDragEnd = (result: any) => {
        if (!result.destination) return;

        const items = Array.from(platformBars);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        console.log("New platformBars order after drag:", items);

        // Ensuring userStreamerProfile and embeddedPlayers are not null/undefined
        const aboutMePlatformIcons = userStreamerProfile?.aboutMePlatformIcons ?? [];
        setIsChanged(!arraysEqual(items, aboutMePlatformIcons));

        setPlatformBars([...items]); // Deep clone the array
    };

    return (
        <div className="embeddable-player-container">
            <div className="add-platform-container">
                <button className="add-platform-button" onClick={handleAddPlatform}>
                    {/* Existing SVG for Add Video to Page button */}
                    <svg width="16" height="16" viewBox="0 -1 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M8 0C8.55228 0 9 0.447715 9 1V7H15C15.5523 7 16 7.44772 16 8C16 8.55228 15.5523 9 15 9H9V15C9 15.5523 8.55228 16 8 16C7.44772 16 7 15.5523 7 15V9H1C0.447715 9 0 8.55228 0 8C0 7.44772 0.447715 7 1 7H7V1C7 0.447715 7.44772 0 8 0Z" fill="white" />
                    </svg>
                    Add Platform to "About"
                </button>
                <SaveButton onSave={handleSave} isDisabled={!isChanged || platformBars.length === 0} />
            </div>
            <DragDropContext onDragEnd={onDragEnd} key={platformBars.length}>
                <Droppable droppableId="platformBars">
                    {(provided) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className="platforms-area"
                        >
                            {platformBars.map((bar, index) => (
                                <Draggable key={bar.id} draggableId={bar.id} index={index}>
                                    {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <PlatformBarIcons
                                                initialPlatform={bar.platform}
                                                initialStreamerName={bar.username}
                                                index={index}
                                                handleRemovePlatform={handleRemovePlatform}
                                                onUpdate={handlePlatformBarUpdate}
                                            />
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    );
};

export default PlatformIcons;
