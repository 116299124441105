// src/components/SettingsCloseButton.tsx

import React from 'react';
import { useDispatch } from 'react-redux';
import { toggleSettings } from '../../../store/reducers/settingsReducer'; // Update the path as needed
import { CloseButtonStyle } from './ButtonStyles'; // Update the import path as needed

const SettingsCloseButton: React.FC = () => {
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(toggleSettings());
    };

    return (
        <CloseButtonStyle onClick={handleClose} />
    );
};

export default SettingsCloseButton;
