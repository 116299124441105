import { Skeleton } from "@radix-ui/themes";
import { useSelector } from "react-redux";
import { getActivePlayer } from "../../../../store/slices/EmbeddedPlayer";
import { useStreamerProfile } from "../../../contexts/StreamerProfileContext";
import EmbeddedPlayer from "./EmbeddedPlayer";
import { usePlayerScale } from "../../../../hooks/usePlayerScale";
import { useInventoryImage } from "../../../../hooks/useInventoryImage";
import Draggable from 'react-draggable';

function ShimmerPlayer() {
  const { status } = useStreamerProfile();
  const { platform, url } = useSelector(getActivePlayer);
  const scale = usePlayerScale();
  const { inventoryImage, updatePosition } = useInventoryImage(scale);

  if (status === "loading") {
    return <Skeleton height="100%" />;
  }

  const handleRemove = () => {
    // Logic to remove the image or perform any action on remove
    console.log("X button clicked");
    // Here you can add logic to remove the image or hide it, etc.
  };

  const handleUse = () => {
    console.log("Use button clicked");
    // Add logic to use the item or trigger some action
  };

  if (platform && url) {
    return (
      <>
        <EmbeddedPlayer platform={platform} url={url} />
        {inventoryImage && (
          <Draggable
            position={inventoryImage.position}
            onStop={(e, data) => {
              updatePosition(data.x, data.y); // Update position on drag end
            }}
            bounds="#player" // Restrict dragging within the player area
          >
            <div style={inventoryImage.style}>
              <img
                src={inventoryImage.src}
                width={inventoryImage.width}
                height={inventoryImage.height}
                alt="Inventory Item"
              />
              {/* X Button */}
              <button
                onClick={handleRemove}
                style={{
                  position: 'absolute',
                  top: '2px',
                  right: '2px',
                  width: '20px',
                  height: '22px',
                  backgroundColor: 'red',
                  color: 'white',
                  border: 'none',
                  fontSize: '14px',
                  lineHeight: '20px',
                  textAlign: 'center',
                  cursor: 'pointer',
                  zIndex: 20,
                  padding: 0,
                }}
              >
                &times;
              </button>

              {/* Use Button */}
              <button
                onClick={handleUse}
                style={{
                  position: 'absolute',
                  bottom: '2px',
                  right: '2px',
                  height: '35px',
                  width: '60px',
                  backgroundColor: 'green',
                  color: 'white',
                  border: 'none',
                  fontSize: '12px',
                  lineHeight: '20px',
                  textAlign: 'center',
                  cursor: 'pointer',
                  zIndex: 20,
                  padding: '0 5px',
                }}
              >
                Use
              </button>
            </div>
          </Draggable>
        )}
      </>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      No embedded player available.
    </div>
  );
}

export default ShimmerPlayer;
