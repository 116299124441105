import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { getChatPaused, setChatPaused } from "../../../store/slices/Chat"
import {
  addStreamerPageYoutubeVideoId,
  removeStreamerPageYoutubeVideoId,
} from "../../../store/slices/StreamerPage"
import { useToken } from "../../contexts/TokenContext"
import { useUserProfile } from "../../contexts/UserProfileContext"
import { useWebSocket } from "../../contexts/WebSocketContext"
import ChatMessage, { MessageData } from "../chatmessage/ChatMessageModel"
import { useAlertVisibility } from "../../contexts/AlertVisibilityContext"

const MAX_MESSAGES = 150

// Well uhh i mean this works?? LMAO
let alternate = true
function getBackground(disableRotatingChatBackground?: boolean) {
  if (disableRotatingChatBackground) return

  alternate = !alternate
  return alternate ? "var(--panel-default-color)" : undefined
}

function isMessageData(data: unknown): data is MessageData | MessageData[] {
  return (
    data != null &&
    ((typeof data === "object" && "type" in data) || Array.isArray(data))
  )
}

function useChatWebSocket() {
  const dispatch = useDispatch()

  const { username } = useParams()

  const { socket, status } = useWebSocket()
  const { token } = useToken()
  const { profile } = useUserProfile()
  const { addAlert } = useAlertVisibility(); // Get the setAlerts function from context

  const isChatPaused = useSelector(getChatPaused)
  const [messages, setMessages] = useState<ChatMessage[]>([])
  const [buffer, setBuffer] = useState<ChatMessage[]>([])

  const pushed = useRef<string[]>([])

  // useRandomMessageGenerator(setMessages, 200) // Hook for random messages

  // Reset chat on streamer change
  useEffect(() => {
    console.log("Resetting chat on streamer change")

    setMessages([])
    setBuffer([])
    dispatch(setChatPaused(false))
  }, [dispatch, username])

  useEffect(() => {
    let message = ""

    switch (status) {
      case "connected":
        message = "Connected!"
        break

      case "disconnected":
        message = "Disconnected!"
        break

      case "error":
        message = "Error connecting to chat."
        break
    }

    if (message) {
      const statusMessage = new ChatMessage("System", "announcement", {
        type: "System",
        message,
      })

      setMessages((prev) => [...prev, statusMessage])
    }
  }, [status])

  const handleMessage = useCallback(
    (data: MessageData) => {
      const { settings } = profile ?? {}

      const background = getBackground(settings?.disableRotatingChatBackground)
      const defaultArgs = ["user", data, { background }] as const

      console.log("THIS IS DATA: " + JSON.stringify(data.data));
      if (data.data?.topic) {
        // Use the addAlert function to add a new alert
        addAlert({
          _id: `alert-${Date.now()}`, // Generate a unique ID
          topic: data.data.topic,
          data: data.data.data,
          timestamp: new Date().toISOString(),
        })
      }


      switch (data.type) {
        case "connectedClientsCount":
          const announcementData = {
            type: data.type,
            message: `Users Online: ${data.count}`,
          }

          return new ChatMessage("System", "announcement", announcementData)

        case "ChatRPG":
          if (settings?.disableChatRPGChat) return null
          return new ChatMessage("ChatRPG", ...defaultArgs)

        case "TwitchChatRPG":
          if (settings?.disableTwitchChat) return null
          return new ChatMessage("TwitchChatRPG", ...defaultArgs)

        case "YoutubeChatRPG":
          if (settings?.disableYoutubeChat) return null
          return new ChatMessage("YoutubeChatRPG", ...defaultArgs)

        case "Twitch":
          if (settings?.disableTwitchChat) return null
          return new ChatMessage("Twitch", ...defaultArgs)

        case "Trovo":
          return new ChatMessage("Trovo", ...defaultArgs)

        case "Tiktok":
          if (settings?.disableTiktokChat) return null
          return new ChatMessage("Tiktok", ...defaultArgs)

        case "Kick":
          if (settings?.disableKickChat) return null
          return new ChatMessage("Kick", ...defaultArgs)

        case "Youtube":
          if (settings?.disableYoutubeChat) return null
          return new ChatMessage("Youtube", ...defaultArgs)

        //CHAT RELATED ABOVE ^ Chat Events BELOW v
        case "Event":
          console.log(`Subscription data: ${JSON.stringify(data)}`)
          return new ChatMessage("Event", "user", data)

        case "CustomEvent":
          console.log(`Custom Event Data is coming in: ${JSON.stringify(data)}`)
          return new ChatMessage("CustomEvent", "user", data)

        case "System":
          const matches = /(offline)?! Video ?ID: (.*)$/i.exec(data.message)

          if (matches) {
            const [, isStopped, videoId] = matches

            if (isStopped) {
              dispatch(removeStreamerPageYoutubeVideoId(videoId))
            } else {
              dispatch(addStreamerPageYoutubeVideoId(videoId))
            }
          }

          return new ChatMessage("System", "user", data)

        default:
          console.error(`Unhandled message type: ${data.type}`)
      }
    },
    [dispatch, profile]
  )

  const processMessage = useCallback(
    (data: MessageData) => {
      const message = handleMessage(data)

      if (!message) return

      if ((token && !profile) || isChatPaused) {
        if (messages.length < MAX_MESSAGES) {
          // Continue pushing messages if chat hasn't reached max amount to
          // make sure the chat scroll height is not too small on pause.
          //
          // This can be improved further by using a virtualizer, so that
          // we don't have to limit the amount of pushed messages or
          // eliminate the need for the buffer altogether.
          //
          // P.S. This may seem like double buffering, but we need the buffer to
          // count for messages that are received during the pause anyway
          // Also, we might need it for other things, so I'm leaving it as is
          // until we know for sure we really don't need its contents
          setMessages((prev) => [...prev, message])
          pushed.current.push(message.key)
        }

        return setBuffer((prev) => [...prev, message])
      }

      setMessages((prev) => [...prev, message].slice(-MAX_MESSAGES))
    },
    [handleMessage, isChatPaused, messages.length, profile, token]
  )

  useEffect(() => {
    const messageHandler = (event: MessageEvent) => {
      if (status !== "connected") return

      // logWithYellowBackground(
      //   `Received message in ChatWebSocketDisplay.tsx: ${event.data}`
      // )

      const data = JSON.parse(event.data)

      if (!isMessageData(data)) return

      if (Array.isArray(data)) {
        return data.forEach(processMessage)
      }

      processMessage(data)
    }

    if (status === "connected") {
      socket.addEventListener("message", messageHandler)
      return () => socket.removeEventListener("message", messageHandler)
    }

    // if (status === "disconnected") {
    //   socket.removeEventListener("message", messageHandler)
    //   setMessages([])
    // }
  }, [processMessage, socket, status])

  // Flush buffer once profile data is loaded
  useEffect(() => {
    if (token && profile && buffer.length > 0 && !isChatPaused) {
      setMessages((prev) =>
        [
          ...prev,
          ...buffer.filter((b) => !pushed.current.includes(b.key)),
        ].slice(-MAX_MESSAGES)
      )

      setBuffer([])
    }
  }, [buffer, handleMessage, isChatPaused, profile, token])

  return useMemo(() => ({ messages, buffer }), [buffer, messages])
}

export default useChatWebSocket
