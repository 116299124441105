// src/store/reducers/theatreModeReducer.ts

// Action Types
const TOGGLE_THEATRE_MODE = 'TOGGLE_THEATRE_MODE';
const SET_THEATRE_MODE = 'SET_THEATRE_MODE';

// Action Creators
export const toggleTheatreMode = () => ({
    type: TOGGLE_THEATRE_MODE as typeof TOGGLE_THEATRE_MODE,
});

// State Type
interface TheatreModeState {
    isTheatreMode: boolean;
}

// Initial State
const initialState: TheatreModeState = {
    isTheatreMode: false,
};

// New Action Creator
export const setTheatreMode = (isTheatreMode: boolean) => ({
    type: SET_THEATRE_MODE,
    payload: isTheatreMode,
});

// Reducer with explicit types
const theatreModeReducer = (state: TheatreModeState = initialState, action: { type: string; payload?: any }): TheatreModeState => {
    switch (action.type) {
        case TOGGLE_THEATRE_MODE:
            return { ...state, isTheatreMode: !state.isTheatreMode };
        case SET_THEATRE_MODE:
            return { ...state, isTheatreMode: action.payload };
        default:
            return state;
    }
};

export default theatreModeReducer;