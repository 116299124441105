import axios from "axios"
import {
  Extension,
  UserDeveloperProfile,
} from "../../../store/reducers/userDeveloperProfileReducer"

const SERVER_URL = process.env.REACT_APP_SERVER_URL

export async function saveExtension(token: string | null, data: Extension) {
  if (!token) throw new Error("Unauthorized.")

  // POST request to the endpoint with the single extension's data
  const response = await axios.post<UserDeveloperProfile>(
    `${SERVER_URL}/developer/create`,
    data,
    { headers: { Authorization: `Bearer ${token}` } }
  )

  // Assuming the server responds with the updated or new extension data, including a new clientSecret if relevant
  return response.data
}

export async function submitExtensionForReview(
  clientId: string,
  token: string | null
) {
  if (!token) throw new Error("Unauthorized.")

  const response = await axios.post<UserDeveloperProfile>(
    `${SERVER_URL}/developer/submitreview`,
    { clientId },
    { headers: { Authorization: `Bearer ${token}` } }
  )

  // Assuming the server responds with the updated developer object
  return response.data
}

export async function cancelExtensionReview(
  clientId: string,
  token: string | null
) {
  if (!token) throw new Error("Unauthorized.")

  const response = await axios.post<UserDeveloperProfile>(
    `${SERVER_URL}/developer/cancelreview`,
    { clientId },
    { headers: { Authorization: `Bearer ${token}` } }
  )

  // Assuming the server responds with the updated developer object
  return response.data
}

export async function removeOwnExtension(
  clientId: string,
  token: string | null
) {
  if (!token) throw new Error("Unauthorized.")

  const response = await axios.post<UserDeveloperProfile>(
    `${SERVER_URL}/developer/remove`,
    { clientId },
    { headers: { Authorization: `Bearer ${token}` } }
  )

  return response.data
}

export async function enableExtension(clientId: string, token: string | null) {
  if (!token) throw new Error("Unauthorized.")

  const response = await axios.post<UserDeveloperProfile>(
    `${SERVER_URL}/extensions/user/enable`,
    { clientId },
    { headers: { Authorization: `Bearer ${token}` } }
  )

  return response.data
}

export async function disableExtension(clientId: string, token: string | null) {
  if (!token) throw new Error("Unauthorized.")

  const response = await axios.post(
    `${SERVER_URL}/extensions/user/disable`,
    { clientId },
    { headers: { Authorization: `Bearer ${token}` } }
  )

  return response.data
}

export async function installExtension(clientId: string, token: string | null) {
  if (!token) throw new Error("Unauthorized.")

  const response = await axios.post(
    `${SERVER_URL}/extensions/user/add`,
    { clientId },
    { headers: { Authorization: `Bearer ${token}` } }
  )

  return response.data
}

export async function uninstallExtension(
  clientId: string,
  token: string | null
) {
  if (!token) throw new Error("Unauthorized.")

  const response = await axios.post(
    `${SERVER_URL}/extensions/user/remove`,
    { clientId },
    { headers: { Authorization: `Bearer ${token}` } }
  )

  return response.data
}
