import { Box, Text } from "@radix-ui/themes"
import { ReactNode } from "react"
import styled from "styled-components"

interface WarningMessageProps {
  visible: boolean
  message: ReactNode
  warning?: boolean
}

function InputMessage({ visible, message, warning }: WarningMessageProps) {
  return (
    <Wrapper py="2" mx="1" $isVisible={visible}>
      {warning && (
        <svg width="18" height="18" viewBox="0 0 24 24">
          <path d="M12 2C12 2 1 21 1 21H23L12 2Z" fill="#FFD700" />
          <path d="M11 16H13V18H11V16ZM11 8H13V14H11V8Z" fill="#1C1C1C" />
        </svg>
      )}

      <Text>{message}</Text>
    </Wrapper>
  )
}

const Wrapper = styled(Box)<{ $isVisible: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;

  font-family: "SF", sans-serif;
  font-size: 0.875rem;
  color: #d8d8d8;
  background-color: #18181b;
  border-radius: 10px 10px 0 0;
  border: 1px solid #a1a1a1;
  border-bottom: none;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);

  position: absolute;
  bottom: ${({ $isVisible }) => ($isVisible ? "100%" : "0")};
  opacity: ${({ $isVisible }) => ($isVisible ? "1" : "0")};
  transition: 75ms ease-out;
  transition-property: bottom, opacity;
  width: calc(100% - 0.5rem);

  svg {
    margin-bottom: 1px;
  }
`

export default InputMessage
