import { useCallback } from "react";
import PLATFORMS from "../../../../../config/platformConfig";
import { arraysEqual } from "../../../../utils/arrayEquals";
import { PlatformBar } from "./IPlatformBar";

export const usePlatformBarHandlers = (
    platformBars: PlatformBar[],
    setPlatformBars: React.Dispatch<React.SetStateAction<PlatformBar[]>>,
    initialPlatformBars: PlatformBar[],
    setIsChanged: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    const handleAddPlatform = useCallback(() => {
        const embeddablePlatforms = Object.keys(PLATFORMS).filter(key => PLATFORMS[key].embeddable);
        const defaultPlatform = embeddablePlatforms[0];
        const newId = `platform-bar-${Date.now()}`; // Using Date.now() for more uniqueness
        const newPlatformBar = { id: newId, platform: defaultPlatform, username: '' };
        
        setPlatformBars(prevBars => [...prevBars, newPlatformBar]);
        setIsChanged(true);
    }, [setPlatformBars, setIsChanged]);


    const handleRemovePlatform = useCallback((index: number) => {
        setPlatformBars(prevBars => {
            const newBars = prevBars.filter((_, i) => i !== index);
            setIsChanged(!arraysEqual(newBars, initialPlatformBars));
            return newBars;
        });
    }, [setPlatformBars, initialPlatformBars, setIsChanged]);

    const handlePlatformBarUpdate = useCallback((index: number, platform: keyof typeof PLATFORMS, username: string) => {
        setPlatformBars(prevBars => {
            const newBars = prevBars.map((bar, i) =>
                i === index ? { ...bar, platform, username } : bar
            );
            setIsChanged(!arraysEqual(newBars, initialPlatformBars));
            return newBars;
        });
    }, [setPlatformBars, initialPlatformBars, setIsChanged]);

    return { handleAddPlatform, handleRemovePlatform, handlePlatformBarUpdate };
}