import { Button, Text } from "@radix-ui/themes"
import { PiCurrencyCircleDollarBold } from "react-icons/pi"
import { styled } from "styled-components"

interface DonateButtonProps {
  url: string
}

function DonateButton({ url }: DonateButtonProps) {
  const handleDonateClick = () => {
    window.open(url, "_blank")
  }

  return (
    <Wrapper className="donate-button" onClick={handleDonateClick}>
      <PiCurrencyCircleDollarBold size="20" />
      <Text weight="bold">Donate</Text>
    </Wrapper>
  )
}

const Wrapper = styled(Button)`
  font-family: "SF", sans-serif;
  font-size: 1rem;
  transition: background-color 0.3s, width 0.3s;

  &:hover svg {
    transition: transform 0.2s ease;
    animation: wiggleAndGrow 0.5s ease-in-out infinite;
  }
`

export default DonateButton
