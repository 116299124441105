import { useState, useEffect } from 'react';

export function usePlayerScale() {
  const [scale, setScale] = useState(100);

  useEffect(() => {
    const updateScale = () => {
      const playerContainer = document.getElementById('player');
      if (playerContainer) {
        const playerWidth = playerContainer.offsetWidth;
        const playerHeight = playerContainer.offsetHeight;

        const widthScale = playerWidth / 1920;
        const heightScale = playerHeight / 1080;

        setScale(Math.min(widthScale, heightScale) * 100); // Update scale based on player size
      }
    };

    updateScale(); // Initial scale calculation
    window.addEventListener('resize', updateScale); // Listen for window resize

    return () => window.removeEventListener('resize', updateScale);
  }, []);

  return scale;
}
