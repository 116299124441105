import { Button, ScrollArea } from "@radix-ui/themes"
import { FaPause } from "react-icons/fa"
import { styled } from "styled-components"
import { useMobileContext } from "../../contexts/MobileContext"
import ChatLine from "../chatmessage/ChatLine"
import ChatNotificationBox from "../ChatNotificationBox"
import useChatScroll from "./ChatScrollLogic"
import useChatWebSocket from "./ChatWebSocketDisplay"

function resolvePausedMessageText(count: number, max: number) {
  if (count > 0) {
    let message = `${Math.min(max, count)}`
    message += `${count > max ? "+" : ""}`
    message += ` message${count > 1 ? "s" : ""} paused`

    return message
  }

  return "Chat Paused"
}

function ChatDisplay() {
  const { isMobile } = useMobileContext()
  const { messages, buffer } = useChatWebSocket()

  // const virtualizer = useVirtualizer({
  //   count: messages.length,
  //   getScrollElement: () => scrollElementRef.current,
  //   getItemKey: (index) => messages[index].key,
  //   estimateSize: () => 45,
  //   initialOffset: 0,
  //   overscan: 5,
  // })

  // const items = virtualizer.getVirtualItems()

  const {
    handleScroll,
    scrollToBottom,
    scrollElementRef,
    showScrollToBottomButton,
    pausedMessageCount,
  } = useChatScroll(messages, buffer, {
    // onChange: (m) => {
    //   if (m.length > 0 && !showScrollToBottomButton) {
    //     virtualizer.scrollToIndex(m.length - 1)
    //   }
    // },
  })

  return (
    <ScrollWrapper
      scrollbars="vertical"
      // type="always"
      $mobile={isMobile}
      onScroll={handleScroll}
      ref={scrollElementRef}
    >
      {/* <VirtualizedWrapper style={{ height: virtualizer.getTotalSize() }}> */}
      {/* <VirtualizedDynamicContainer
          style={{ transform: `translateY(${items[0]?.start ?? 0}px)` }}
        > */}
      {messages.map((message) => {
        //message holds type + data - and data holds data
        switch (message.type) {
          case "Event":
          case "CustomEvent":
            console.log("This is our event: " + JSON.stringify(message))
          
            return (
              <ChatNotificationBox
                key={message.key}
                message={message}
                // index={index}
                // ref={virtualizer.measureElement}
              />
            )

          case "None":
            return null

          default:
            return (
              <ChatLine
                key={message.key}
                message={message}
                // index={index}
                // ref={virtualizer.measureElement}
              />
            )
        }
      })}
      <div />
      {/* </VirtualizedDynamicContainer> */}
      {/* </VirtualizedWrapper> */}

      {showScrollToBottomButton && (
        <ScrollToBottom>
          <Button
            size="3"
            color="gray"
            variant="surface"
            // onClick={() => virtualizer.scrollToIndex(messages.length - 1)}
            onClick={() => scrollToBottom({ force: true })}
          >
            <FaPause size="15" />

            {resolvePausedMessageText(pausedMessageCount, 99)}
          </Button>
        </ScrollToBottom>
      )}
    </ScrollWrapper>
  )
}

const ScrollWrapper = styled(ScrollArea)<{ $mobile: boolean }>`
  flex-grow: 1;
  padding-top: ${({ $mobile }) => ($mobile ? "0" : "0.5rem")};

  .rt-ScrollAreaScrollbar {
    margin: 0;
    width: 0.375rem;
  }
`

// const VirtualizedWrapper = styled.div`
//   position: relative;
//   width: 100%;
// `

// const VirtualizedDynamicContainer = styled.div`
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
// `

const ScrollToBottom = styled.div`
  position: absolute;
  bottom: 0.5rem;
  left: 50%;
  transform: translateX(-50%);
  min-width: max-content;

  button {
    background-color: #212121;
    color: white;
  }
`

export default ChatDisplay
