import { useState, useEffect } from 'react';

interface InventoryImage {
  src: string;
  width: number;
  height: number;
  style: React.CSSProperties;
  position: { x: number; y: number }; // Track the position
}

export function useInventoryImage(scale: number) {
  const [inventoryImage, setInventoryImage] = useState<InventoryImage | null>(null);
  const [originalImageDimensions, setOriginalImageDimensions] = useState<{ width: number; height: number } | null>(null);


  useEffect(() => {
    const handleMessage = (event: any) => {
      if (event.data.type === 'USE_INVENTORY_ITEM') {
        const img = new Image();
        img.src = event.data.payload.imageUrl;

        img.onload = () => {
          const originalImageWidth = img.naturalWidth;
          const originalImageHeight = img.naturalHeight;

          setOriginalImageDimensions({ width: originalImageWidth, height: originalImageHeight });

          // Assuming default position at 10, 10 relative to a 1920x1080 canvas
          const defaultX = 10;
          const defaultY = 10;

          // Scale the default position
          const scaledX = (defaultX / 1920) * 100;
          const scaledY = (defaultY / 1080) * 100;


          console.log("Default Position:", { defaultX, defaultY });
          console.log("Scaled Position:", { scaledX, scaledY });
          console.log("Scale Factor:", scale);


          setInventoryImage({
            src: img.src,
            width: (originalImageWidth * scale) / 100,
            height: (originalImageHeight * scale) / 100,
            style: {
              position: 'absolute',
              top: `${(scaledY * scale) / 100}px`,
              left: `${(scaledX * scale) / 100}px`,
              zIndex: 10,
            },
            position: { x: scaledX, y: scaledY }, // Scaled position
          });
        };
      }
    };
    window.addEventListener('message', handleMessage);

    return () => window.removeEventListener('message', handleMessage);
  }, [scale]);

  useEffect(() => {
    if (originalImageDimensions && inventoryImage) {

      console.log("New Scale:", scale);

      setInventoryImage({
        ...inventoryImage,
        width: (originalImageDimensions.width * scale) / 100,
        height: (originalImageDimensions.height * scale) / 100,
        
      });

      updatePosition(inventoryImage.position.x * scale / 100, inventoryImage.position.y * scale / 100);

      console.log("Updated Inventory Image:", inventoryImage);
    }
  }, [scale, originalImageDimensions]);

  
  const updatePosition = (x: number, y: number) => {
    setInventoryImage(prevInventoryImage => {
      if (!prevInventoryImage) return null;
  
      console.log("Updating Position:");
      console.log("New Position:", { x, y });
  
      // Return a new object with only the position updated
      return {
        ...prevInventoryImage,
        position: { x, y },
        style: {
          ...prevInventoryImage.style,
          top: `${y}px`,
          left: `${x}px`,
        },
      };
    });
  };
  

  return { inventoryImage, updatePosition };
}
