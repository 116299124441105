import React from 'react';
import './FinishedPanel.css'; // Ensure you have this CSS file
import DOMPurify from 'dompurify';

type PanelProps = {
    title: string;
    imageUrl?: string;
    description?: string;
    htmlDescription?: string;
    link?: string;
};

const handleImageError = (e: any) => {
    e.target.style.display = 'none'; // Hide broken image
};

const FinishedPanel: React.FC<PanelProps> = ({ title, imageUrl, description, htmlDescription, link }) => {

    const createMarkup = (htmlContent: any) => {
        return { __html: DOMPurify.sanitize(htmlContent) };
    };

    return (
        <div className="finished-panel">
            <h3>{title}</h3>
            {imageUrl && (
                link ?
                    <a href={link} target="_blank" rel="noopener noreferrer">
                        <img src={imageUrl} alt={title} onError={handleImageError} className="finished-panel-image" />
                    </a> :
                    <img src={imageUrl} alt={title} onError={handleImageError} className="finished-panel-image" />
            )}
            <div className="description" dangerouslySetInnerHTML={createMarkup(htmlDescription || '')} />
        </div>
    );
};

export default FinishedPanel;
