import { FaBars, FaXmark } from "react-icons/fa6"
import { styled } from "styled-components"
import { media } from "../styled.utils"
import { useToggle } from "usehooks-ts"
import BaseButton from "./Button.styled"
import Container from "./Container.styled"
import LoginButtonWithAuth from "../../ui/button/LoginButton"

interface HeaderProps {
  $dark: boolean
}

const routes = [
  { label: "Terms of Service", path: "https://chatrpg.com/terms-of-service" },
  { label: "Privacy Policy", path: "https://chatrpg.com/privacy-policy" },
]


function NavigationBar({ $dark }: HeaderProps) {
  const [showMenu, toggleMenu] = useToggle()

  const MobileMenuButtonIcon = showMenu ? FaXmark : FaBars

  return (
    <Header $dark={$dark}>
      <Container as="nav">
        <BrandLink href="/">
          <BrandImage src="/assets/UI/Images/logo192.png" alt="ChatRPG Logo" />
          <BrandName>
            Chat<span>RPG</span>
          </BrandName>
        </BrandLink>

        <MobileNavigation>
          <CTAButton>Get Started</CTAButton>

          <button type="button" id="burger" onClick={toggleMenu}>
            <span className="sr-only">Open main menu</span>
            <MobileMenuButtonIcon aria-hidden />
          </button>
        </MobileNavigation>

        <NavigationLinks>
          {routes.map(({ label, path }) => (
            <li key={path}>
              <NavLink href={path}>{label}</NavLink>
            </li>
          ))}

          <li>
            <LoginButtonWithAuth />
          </li>
        </NavigationLinks>
      </Container>

      <Dialog $show={showMenu}>
        <NavigationLinks>
          {routes.map(({ label, path }) => (
            <li key={path}>
              <NavLink href={path}>{label}</NavLink>
            </li>
          ))}
        </NavigationLinks>

        <Backdrop onClick={() => showMenu && toggleMenu()} />
      </Dialog>
    </Header>
  )
}

const Header = styled.header<HeaderProps>`
  position: sticky;
  width: 100%;
  top: 0;
  max-width: none;
  padding: var(--space-xs) 0;
  z-index: 99;
  background-color: ${({ $dark }) => ($dark ? "#0d1f2d" : "#fff")};

  ${media.lg`
    padding: var(--space-s) 0;
  `}

  a,
  button#burger {
    color: ${({ $dark }) => ($dark ? "#fff" : "#0d1f2d")};
  }

  ${Container} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 0;
    padding-bottom: 0;
    background-color: inherit;

    ${media.xl`
      padding-left: 0;
      padding-right: 0;
    `}
  }
`

const BrandLink = styled.a`
  display: flex;
  align-items: center;
  column-gap: 1rem;
`

const BrandImage = styled.img`
  max-width: var(--step-5);

  ${media.md`
    max-width: var(--step-4);
  `}
`

const BrandName = styled.p`
  display: none;
  color: inherit;
  font-family: "Protest Strike", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: calc(var(--step-1) * 1.15);
  font-weight: 100;

  ${media.md`
    display: block;
  `}

  span {
    color: #f7b339;
  }
`

const NavigationLinks = styled.ul`
  display: none;
  justify-content: center;
  align-items: center;
  gap: calc(var(--space-l) * 1.2);

  ${media.lg`
    display: flex;
  `}
`

const NavLink = styled.a`
  list-style: none;
  text-decoration: none;
  font-size: var(--step-0);
  font-weight: 600;
  padding: 0.875rem 1.375rem;

  ${media.lg`
    padding: 0;
    font-size: var(--step--1);
  `}
`

const CTAButton = styled(BaseButton)`
  padding: 1rem 1.5rem;
  font-size: var(--step-0);
  font-weight: 600;

  ${media.lg` 
    font-size: var(--step--1);
  `}
`

const MobileNavigation = styled.div`
  display: flex;
  gap: 1rem;

  ${media.lg`
    display: none;
  `}

  button#burger {
    padding: 0.5rem;
    padding-right: 0.375rem;
    font-size: var(--step-2);
  }

  ${CTAButton} {
    padding: 0.75rem 1rem;
    font-size: var(--step-0);
  }
`

const Backdrop = styled.div`
  position: fixed;
  inset: 0;
  top: 6rem;
  background-color: black;
  transition: opacity 600ms cubic-bezier(0.4, 0, 0.2, 1);
  will-change: opacity;
  z-index: -1;
`

const Dialog = styled.div<{ $show: boolean }>`
  position: absolute;
  overflow: hidden;
  width: 100%;
  background-color: inherit;

  max-height: ${({ $show }) => ($show ? "100vh" : "0")};
  transition: max-height 300ms cubic-bezier(0.4, 0, 0.2, 1);
  will-change: max-height;
  transform-origin: top;

  ${media.lg`
    display: none;
  `}

  ${Backdrop} {
    opacity: ${({ $show }) => ($show ? "0.4" : "0")};
  }

  ${NavigationLinks} {
    display: flex;
    flex-direction: column;
    gap: var(--space-l);
    padding-top: 2rem;
    padding-bottom: 1.875rem;
  }
`

export default NavigationBar
