import axios from 'axios';
import { Dispatch } from 'redux';

// Updated fetchPopularExtensions without categories
export const fetchPopularExtensions = async (token: string | null, dispatch: Dispatch, setExtensions: (extensions?: any[]) => void) => {
    try {
        dispatch({ type: 'START_LOADING' });

        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/extensions/unownedpopularextensions`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });

        const fetchedExtensions = response.data.map((ext: any) => ({
            title: ext.extensions[0].extensionName,
            author: ext.chatrpg_username,
            description: ext.extensions[0].description,
            clientId: ext.extensions[0].clientId,
        }));

        setExtensions(fetchedExtensions);
        dispatch({ type: 'STOP_LOADING' });
    } catch (error) {
        console.error('Failed to fetch popular extensions:', error);
        dispatch({ type: 'STOP_LOADING' });
        setExtensions()
    }
};
