import React from 'react';
import './AddButtonContent.css';

const AddButtonContent: React.FC = () => {
    return (
        <div className="custom-panel-aspect-ratio">
            <div className="custom-panel-svg-container">
                <svg
                    className="custom-panel-plus-symbol"
                    width="100px" height="100px"
                    viewBox="0 0 20 20"
                >
                    <g>
                        <path d="M9 11v5h2v-5h5V9h-5V4H9v5H4v2h5z"></path>
                    </g>
                </svg>
            </div>
        </div>
    );
};

export default AddButtonContent;
