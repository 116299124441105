// InputField.js or InputField.tsx
import React, { ChangeEvent } from 'react';
import { InputTextField } from './InputFieldStyles';

interface InputFieldProps {
    placeholder: string;
    value: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const InputText: React.FC<InputFieldProps> = ({ placeholder, value, onChange }) => (
    <InputTextField
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className={`input-text-field global-focus-style`} // Using both classes
    />
);

export default InputText;
