// usePlatformBars.ts
import { useState, useEffect, useRef } from 'react';
import PLATFORMS from '../../../../../config/platformConfig'; // Adjust import path as needed
import { UserStreamerProfile } from '../../../../../../store/reducers/userStreamerProfileReducer';

export interface PlatformBarData {
    id: string;
    platform: keyof typeof PLATFORMS;
    username: string;
}

export const usePlatformBars = (userStreamerProfile: UserStreamerProfile | undefined) => {
    const [platformBars, setPlatformBars] = useState<PlatformBarData[]>([]);
    const initialPlatformBarsRef = useRef<PlatformBarData[]>([]);

    useEffect(() => {
        const defaultPlatformBars = userStreamerProfile?.embeddedPlayers?.map(player => ({
            id: `platform-bar-${player.platform}-${Math.random().toString(16).slice(2)}`,
            platform: player.platform,
            username: player.username
        })) || [];
        initialPlatformBarsRef.current = defaultPlatformBars;
        setPlatformBars(defaultPlatformBars);
    }, [userStreamerProfile]);

    return { platformBars, setPlatformBars, initialPlatformBars: initialPlatformBarsRef.current };
};
