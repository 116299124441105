import * as Primitives from "@radix-ui/react-popover"
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from "react"
import styled from "styled-components"

interface PopoverContentProps
  extends ComponentPropsWithoutRef<typeof Primitives.Content> {
  noArrow?: boolean
}

export const Popover = Primitives.Root
export const PopoverTrigger = Primitives.Trigger
export const PopoverAnchor = Primitives.Anchor

export const PopoverContent = forwardRef<
  ElementRef<typeof Primitives.Content>,
  PopoverContentProps
>(({ children, noArrow, ...props }, forwardedRef) => (
  <Primitives.Portal>
    <Content sideOffset={5} {...props} ref={forwardedRef}>
      {children}
      {!noArrow && <Arrow />}
    </Content>
  </Primitives.Portal>
))

const Content = styled(Primitives.Content)`
  z-index: 999;
  background-color: white;
  padding: 1rem;
  border-radius: 0.5rem;
`

const Arrow = styled(Primitives.Arrow)`
  fill: white;
`

PopoverContent.displayName = "PopoverContent"
