// src/store/reducers/fullScreenReducer.ts

// Action Types
const TOGGLE_FULLSCREEN_MODE = 'TOGGLE_FULLSCREEN_MODE';
const SET_FULLSCREEN_MODE = 'SET_FULLSCREEN_MODE';

// Action Creators
export const toggleFullscreenMode = () => ({
    type: TOGGLE_FULLSCREEN_MODE as typeof TOGGLE_FULLSCREEN_MODE,
});

// State Type
interface FullscreenModeState {
    isFullscreenMode: boolean;
}

// Initial State
const initialState: FullscreenModeState = {
    isFullscreenMode: false,
};

// New Action Creator
export const setFullscreenMode = (isFullscreenMode: boolean) => ({
    type: SET_FULLSCREEN_MODE,
    payload: isFullscreenMode,
});

// Reducer with explicit types
const fullscreenModeReducer = (state: FullscreenModeState = initialState, action: { type: string; payload?: any }): FullscreenModeState => {
    switch (action.type) {
        case TOGGLE_FULLSCREEN_MODE:
            return { ...state, isFullscreenMode: !state.isFullscreenMode };
        case SET_FULLSCREEN_MODE:
            return { ...state, isFullscreenMode: action.payload };
        default:
            return state;
    }
};

export default fullscreenModeReducer;