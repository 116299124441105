import MiniSearch from "minisearch"
import { KeyboardEvent, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"
import {
  EmoteSearchResult,
  useEmotes,
} from "../../../contexts/EmoteContext"
import { RootState } from "../../../../store"
import { setChatMessage } from "../../../../store/reducers/inputChatReducer"
import AuthModule from "../../../auth/login/AuthModule"
import { useAuthModule } from "../../../auth/login/UseAuthModule"
import { useToken } from "../../../contexts/TokenContext"
import { useWebSocket } from "../../../contexts/WebSocketContext"
import {
  Popover,
  PopoverAnchor,
  PopoverTrigger,
} from "../../../ui/Popover.styled"
import { useSendMessage } from "../../chatHelpers/useSendMessage"
import EmotePopover from "../../EmotePopover"
import TextAreaAutoSize from "./auto-resize-textfield"
import InputMessage from "./helper/InputMessage"
import useCharacterLimit from "./hooks/useInputWithValidation"

// TODO: Do we really need the redux calls?
function ResizableInputForm() {
  const dispatch = useDispatch()

  const { token } = useToken()
  const { status } = useWebSocket()
  const { isAuthModuleOpen, closeAuthModule, handleLoginButtonClick } =
    useAuthModule()
  const sendMessage = useSendMessage()
  const chatMessage = useSelector<RootState, string>(
    (state) => state.chatInput.chatMessage
  )

  const { search } = useEmotes()
  const { inputValue, isLimitReached, isConsecutiveLimitReached } =
    useCharacterLimit(chatMessage)

  const [searchedEmotes, setSearchedEmotes] = useState<EmoteSearchResult[]>([])

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (!token) {
      handleLoginButtonClick() // Open the auth module if there's no token
      return // Prevent further execution
    }

    const newValue = event.target.value
    const isDeleting = inputValue.length > newValue.length

    if (!(isLimitReached || isConsecutiveLimitReached) || isDeleting) {
      dispatch(setChatMessage(newValue))

      // Only query if:
      // 1) has at least one space before it or is start of message
      // 2) thing starts w/ `:` of course
      // Also, only query end of string
      const matches = /(?:^|\s+)(?::(\b\w+\b))$/i.exec(newValue)

      if (matches) {
        const [word] = matches.slice(1)

        setSearchedEmotes(search(word ?? MiniSearch.wildcard))
      } else setSearchedEmotes([])
    }
  }

  const handleKeyPress = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault()
      sendMessage()
      dispatch(setChatMessage("")) // Clear Redux store
    }

    if (event.key === "Tab") {
      event.preventDefault()

      // Only query end of string
      const matches = /(\b\w+\b)$/i.exec(inputValue)

      if (matches) {
        const [word] = matches.slice(1)

        setSearchedEmotes(search(word))
      }
    }
  }

  const inputRef = useRef<HTMLTextAreaElement>(null)

  return (
    <Popover open={!!chatMessage} onOpenChange={() => {}}>
      {isAuthModuleOpen ? <AuthModule onClose={closeAuthModule} /> : null}

      <EmotePopover
        emotes={searchedEmotes}
        message={chatMessage}
        onClose={() => {
          setSearchedEmotes([])
          inputRef.current?.focus()
        }}
      />

      <PopoverAnchor asChild>
        <Wrapper>
          <PopoverTrigger asChild />

          <InputMessage
            visible={status === "connecting"}
            message="Connecting to chat..."
          />
          <InputMessage
            warning
            visible={isLimitReached}
            message="Reached Character Limit of 500"
          />
          <InputMessage
            warning
            visible={isConsecutiveLimitReached}
            message="Please do not repeat characters"
          />

          <TextArea
            ref={inputRef}
            value={chatMessage}
            placeholder="Send a message"
            minRows={1}
            maxRows={4}
            className="global-focus-style"
            onChange={handleInputChange}
            onKeyDown={handleKeyPress}
          />
        </Wrapper>
      </PopoverAnchor>
    </Popover>
  )
}

const Wrapper = styled.form`
  position: relative;
`

const TextArea = styled(TextAreaAutoSize)`
  font-family: "SF", sans-serif;
  width: 100%;
  padding: 10px 10px 10px 42px; /* Left padding includes space for the icon */
  border: none;
  border-radius: 0.25rem;
  background-color: transparent; /* Let the container's background show */
  color: #ececec;
  resize: none;
  overflow: hidden;
  box-shadow: none; /* Remove box-shadow to avoid doubling */
`

export default ResizableInputForm
