import { PlatformBar } from "./IPlatformBar";

export const apiSavePlatformConfiguration = async (platformBars: PlatformBar[], token: string | null) => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/streamer/options/embeddablePlatforms`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(platformBars),
        credentials: 'include',
    });

    if (!response.ok) throw new Error('Failed to save platform configurations');

    return await response.json();
};
