import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
} from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import { fetchExtensionJWT } from "../../store/slices/Auth"
import {
  getActivePlayer,
  resetPlayer,
  setActivePlayer,
} from "../../store/slices/EmbeddedPlayer"
import {
  fetchStreamerPageProfile,
  getAllStreamerEmbeds,
  getStreamerPageStatus,
  getStreamerProfile,
  StreamerPageStatus,
  StreamerProfile,
} from "../../store/slices/StreamerPage"
import { useToken } from "./TokenContext"

export interface Panel {
  id: string
  title: string
  position: string
  imageUrl?: string
  linkUrl?: string
  description?: string
  htmlDescription?: string
}

export interface EmbeddedPlayer {
  platform: string
  username: string
}

export interface Extension {
  clientId: string
  enabled: boolean
  type: string
}

interface Embed extends EmbeddedPlayer {
  url: string
}

interface StreamerProfileContextType {
  streamerProfile: StreamerProfile
  status: StreamerPageStatus
  players: Embed[]
}

const StreamerProfileContext = createContext<StreamerProfileContextType | null>(
  null
)

export function useStreamerProfile() {
  const context = useContext(StreamerProfileContext)

  if (!context) {
    throw new Error(
      "useStreamerProfile must be used within a StreamerProfileProvider"
    )
  }

  return context
}

export function StreamerProfileProvider({ children }: PropsWithChildren) {
  const { username } = useParams()
  const { token } = useToken()

  const dispatch = useDispatch()

  const status = useSelector(getStreamerPageStatus)
  const profile = useSelector(getStreamerProfile)
  const players = useSelector(getAllStreamerEmbeds)
  const activePlayer = useSelector(getActivePlayer)

  useEffect(() => {
    if (!username) return

    // @ts-ignore
    dispatch(fetchStreamerPageProfile(username))

    return () => {
      dispatch(resetPlayer())
    }
  }, [dispatch, username])

  useEffect(() => {
    if (!token || profile.extensions.length === 0) return

    profile.extensions.forEach((extension) => {
      dispatch(
        // @ts-ignore
        fetchExtensionJWT({
          token,
          clientId: extension.clientId,
          streamerId: profile.chatrpg_id,
        })
      )
    })
  }, [dispatch, profile.chatrpg_id, profile.extensions, token])

  useEffect(() => {
    const defaultPlayer = players[0]

    if (status === "loading" || activePlayer.url) return

    dispatch(setActivePlayer({ index: 0, ...defaultPlayer }))
  }, [activePlayer.url, dispatch, players, status])

  const value = useMemo<StreamerProfileContextType>(
    () => ({ status, streamerProfile: profile, players }),
    [players, profile, status]
  )

  // TODO: Gradually remove this context provider in favor of the redux store
  return (
    <StreamerProfileContext.Provider value={value}>
      {children}
    </StreamerProfileContext.Provider>
  )
}
