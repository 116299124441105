import { Text } from "@radix-ui/themes"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import styled, { css } from "styled-components"
import { useEmotes } from "../../contexts/EmoteContext"
import { RootState } from "../../../store"
import { setChatMessage } from "../../../store/reducers/inputChatReducer"
import { parseLinks } from "./helpers/parseLinks"

interface ChatMessageProps {
  message: string
  displayStyle: "regular" | "system"
}

const ChatMessage: React.FC<ChatMessageProps> = React.memo(
  ({ message, displayStyle }) => {
    const dispatch = useDispatch()
    const chatMessage = useSelector<RootState, string>(
      (state) => state.chatInput.chatMessage
    )

    const { parse: parseEmotes } = useEmotes()

    const parsedEmotes = parseEmotes(message, {
      onEmoteClick: (name) => {
        dispatch(setChatMessage(`${chatMessage} ${name}`))
      },
    })

    const processedMessage = parseLinks(parsedEmotes)

    return (
      <Wrapper size="2" wrap="balance" $system={displayStyle === "system"}>
        {processedMessage}
      </Wrapper>
    )
  }
)

const Wrapper = styled(Text)<{ $system: boolean }>`
  word-break: break-word; /* Break long words at the end of the line */
  overflow-wrap: break-word; /* Ensures proper word wrapping in all browsers */
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);

  ${({ $system }) =>
    $system &&
    css`
      font-style: italic;
      color: grey;
      margin-left: 0;
    `}

  & > div {
    vertical-align: -1px;
    display: inline-flex;
    max-height: 0.875rem;
    overflow: visible;
  }

  img {
    display: inline-flex;
    vertical-align: text-top;
    overflow: visible;
    object-fit: cover;
    /* object-position: text-top; */
    max-height: 1.5rem;
    /* border: 1px solid white; */
  }

  a {
    color: lightblue;
  }
`

export default ChatMessage
