import React, { useState, useEffect } from 'react';
import './FollowButton.css';
import { useStreamerProfile } from '../../../contexts/StreamerProfileContext';
import { useUserProfile } from '../../../contexts/UserProfileContext';
import { useToken } from '../../../contexts/TokenContext';


const FollowButton: React.FC = () => {
    const [isFollowing, setIsFollowing] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // New state to track loading
    const { profile, setProfile } = useUserProfile();
    const { streamerProfile } = useStreamerProfile();
    const { token } = useToken();
    const [initialLoad, setInitialLoad] = useState(true);

    useEffect(() => {
        const isAlreadyFollowing = profile?.followedStreamers?.some(s => s.chatrpg_id === streamerProfile?.chatrpg_id) || false;
        setIsFollowing(isAlreadyFollowing);
        setInitialLoad(false); // Add this line
    }, [profile, streamerProfile]);


    const handleFollowClick = async () => {
        if (!streamerProfile || !profile) {
            //Can't follow if no streamer!
            return;
        }

        if (streamerProfile.chatrpg_id === profile.chatrpg_id) {
            //Can't unfollow or follow yourself!
            return;
        }

        setIsLoading(true); // Start loading

        setIsFollowing(!isFollowing);

        const findNewlyFollowedStreamer = (updatedList: any[], currentList: any[]) => {
            return updatedList.find(streamer => !currentList.some(s => s.chatrpg_id === streamer.chatrpg_id));
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/follow`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`, // Include the token here
                },
                body: JSON.stringify({
                    streamerChatrpgId: streamerProfile.chatrpg_id,
                    streamerUsername: streamerProfile.chatrpg_username,
                    // streamerObjectId can be added here if it's stored in the context or retrieved some other way
                }),
            });

            if (!response.ok) {
                setIsLoading(false);
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setIsFollowing(data.isFollowing);
            if (profile) {
                const newStreamer = findNewlyFollowedStreamer(data.followedStreamers, profile.followedStreamers);

                console.log(newStreamer);
                // Add a console log here
                if (newStreamer) {
                    console.log("Newly followed streamer:", newStreamer.chatrpg_username);
                }

                setProfile({
                    ...profile,
                    followedStreamers: data.followedStreamers, // Assuming the server sends back the updated list
                });
            }
            setIsLoading(false);
        } catch (error) {
            console.error('Error handling follow click:', error);
            // Reset the button state in case of error
            setIsFollowing(!isFollowing);
        }
    };

    if (initialLoad) return null; // Or render a placeholder

    return (
        <button className={`follow-button ${isFollowing ? 'followed' : 'unfollowed'}`} onClick={handleFollowClick} disabled={isLoading}>
            {isFollowing ? <HeartIcon /> : <><HeartIconOutlined /><span className="follow-text"> Follow</span></>}
        </button>
    );
};


const HeartIcon = () => (
    <svg className="heart-icon" viewBox="0 0 24 24" width="20" height="20">
        <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
    </svg>
);

const HeartIconOutlined = () => (
    <svg className="heart-icon-outlined" viewBox="0 0 24 24" width="16" height="16">
        <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" stroke="black" strokeWidth="3" />
    </svg>
);



export default FollowButton;