import { FaUserCog } from "react-icons/fa"
import { RiChatSettingsFill } from "react-icons/ri"
import { useStreamerProfile } from "../../../../contexts/StreamerProfileContext"
import { SettingsButton, SettingsCategory } from "../CategorySharedStyles"

const options = [
  {
    id: "User on Streamer General Settings",
    label: "General Settings",
    Icon: FaUserCog,
  },
  { id: "Chat Badges", label: "Chat Badges", Icon: RiChatSettingsFill },
]

function UserOnStreamerSettings() {
  const { streamerProfile } = useStreamerProfile()

  return (
    <SettingsCategory>
      <h3>
        Your{" "}
        {streamerProfile
          ? `${streamerProfile.chatrpg_username} Settings`
          : "User on Streamer"}
      </h3>

      <ul>
        {options.map(({ id, label, Icon }) => (
          <li key={id}>
            <SettingsButton value={id}>
              <Icon /> <span>{label}</span>
            </SettingsButton>
          </li>
        ))}
      </ul>
    </SettingsCategory>
  )
}

export default UserOnStreamerSettings
