import { Button, ButtonProps, Tooltip } from "@radix-ui/themes"
import {
  ButtonHTMLAttributes,
  ForwardedRef,
  forwardRef,
  ReactNode,
} from "react"
import { RxInfoCircled } from "react-icons/rx"

interface ActionButtonProps
  extends Omit<
      ButtonHTMLAttributes<HTMLButtonElement>,
      "disabled" | "color" | "content"
    >,
    Omit<ButtonProps, "disabled"> {
  disabled?: boolean | DisabledWithMessage
}

interface DisabledWithMessage {
  status: boolean
  content: ReactNode
}

function isDisabledWithMessage(
  disabled: ActionButtonProps["disabled"]
): disabled is DisabledWithMessage {
  return (
    typeof disabled === "object" &&
    "status" in disabled &&
    "content" in disabled
  )
}

function ActionButton(
  { children, disabled, ...props }: ActionButtonProps,
  ref: ForwardedRef<HTMLButtonElement>
) {
  const hasTooltipContent = isDisabledWithMessage(disabled)
  const isDisabled = Boolean(
    hasTooltipContent ? disabled.status && disabled.content : false
  )

  return (
    <Tooltip
      content={hasTooltipContent ? disabled.content : undefined}
      maxWidth="26rem"
      style={{
        zIndex: 9999,
        visibility: isDisabled ? "visible" : "hidden",
      }}
    >
      <Button
        {...props}
        ref={ref}
        disabled={hasTooltipContent ? disabled.status : disabled}
      >
        {isDisabled && <RxInfoCircled />}

        {children}
      </Button>
    </Tooltip>
  )
}

export default forwardRef(ActionButton)
