import React from 'react';
import SettingsCloseButton from '../../ui/button/CloseButton';

const SettingsRightSidebar: React.FC = () => {
    return (
        <div className="right-sidebar">
            <SettingsCloseButton />
        </div>
    );
};

export default SettingsRightSidebar;
