// Define a type for the style object
type StyleObject = { [key: string]: string };

// Define the CSS styles corresponding to your color codes
const styles: StyleObject = {
    redBackground: "background: red; color: white",
    blueBackground: "background: blue; color: white",
    yellowBackground: "background: yellow; color: black",
    greenBackground: "background: green; color: white",
    brownBackground: "background: brown; color: white",
    tealBackground: "background: teal; color: white",
    lightBlueBackground: "background: lightblue; color: black",
    silverBackground: "background: silver; color: black",
    magentaBackground: "background: magenta; color: white",
    cyanBackground: "background: cyan; color: black",
    blackBackground: "background: black; color: white",
    purpleBackground: "background: purple; color: white",
    pinkBackground: "background: pink; color: black",
    orangeBackground: "background: orange; color: black",
    limeBackground: "background: lime; color: black",
    goldenBackground: `background: #f8b426; color: black;`, 
    tanBackground: "background: tan; color: black",
    darkGreenBackground: "background: darkgreen; color: white",
    lightPurpleBackground: "background: plum; color: black",
    peachBackground: "background: peachpuff; color: black",
    salmonBackground: "background: salmon; color: black",
    whiteBackground: "background: white; color: black"
};

function logWithColorAndFileName(colorStyle: string, message: string): void {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    const timestamp = `[${hours}:${minutes}:${seconds}]`;


    console.log(`%c${timestamp} ${message}`, colorStyle);
}

export function logWithRedBackground(message: string): void {
    logWithColorAndFileName(styles.redBackground, message);
}

export function logWithBlueBackground(message: string): void {
    logWithColorAndFileName(styles.blueBackground, message);
}

export function logWithYellowBackground(message: string): void {
    logWithColorAndFileName(styles.yellowBackground, message);
}

export function logWithGreenBackground(message: string): void {
    logWithColorAndFileName(styles.greenBackground, message);
}

export function logWithBrownBackground(message: string): void {
    logWithColorAndFileName(styles.brownBackground, message);
}

export function logWithTealBackground(message: string): void {
    logWithColorAndFileName(styles.tealBackground, message);
}

export function logWithLightBlueBackground(message: string): void {
    logWithColorAndFileName(styles.lightBlueBackground, message);
}

export function logWithSilverBackground(message: string): void {
    logWithColorAndFileName(styles.silverBackground, message);
}

export function logWithMagentaBackground(message: string): void {
    logWithColorAndFileName(styles.magentaBackground, message);
}

export function logWithCyanBackground(message: string): void {
    logWithColorAndFileName(styles.cyanBackground, message);
}

export function logWithBlackBackground(message: string): void {
    logWithColorAndFileName(styles.blackBackground, message);
}

export function logWithPurpleBackground(message: string): void {
    logWithColorAndFileName(styles.purpleBackground, message);
}

export function logWithPinkBackground(message: string): void {
    logWithColorAndFileName(styles.pinkBackground, message);
}

export function logWithOrangeBackground(message: string): void {
    logWithColorAndFileName(styles.orangeBackground, message);
}

export function logWithLimeBackground(message: string): void {
    logWithColorAndFileName(styles.limeBackground, message);
}

export function logWithGoldenBackground(message: string): void {
    logWithColorAndFileName(styles.goldenBackground, message);
}

export function logWithTanBackground(message: string): void {
    logWithColorAndFileName(styles.tanBackground, message);
}

export function logWithDarkGreenBackground(message: string): void {
    logWithColorAndFileName(styles.darkGreenBackground, message);
}

export function logWithLightPurpleBackground(message: string): void {
    logWithColorAndFileName(styles.lightPurpleBackground, message);
}

export function logWithPeachBackground(message: string): void {
    logWithColorAndFileName(styles.peachBackground, message);
}

export function logWithSalmonBackground(message: string): void {
    logWithColorAndFileName(styles.salmonBackground, message);
}

export function logWithWhiteBackground(message: string): void {
    logWithColorAndFileName(styles.whiteBackground, message);
}