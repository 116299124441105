import { EmoteMeta } from "../../components/contexts/EmoteContext"

interface TwitchEmoteFromBackend {
  id: string
  name: string
  images: {
    url_1x: string
    //   url_2x: string; // I only really want the small emoji, remove this comment if disagree
    //   url_4x: string;
  }
}

export async function getTwitchGlobalEmotes(): Promise<EmoteMeta[]> {
  const response = await fetch(
    `${process.env.REACT_APP_SERVER_URL}/global/twitchglobalemoticons`
  )

  if (!response.ok) {
    throw new Error("Failed to fetch Twitch global emotes.")
  }

  const data: TwitchEmoteFromBackend[] = await response.json()

  return data.map<EmoteMeta>(({ id, name, images }) => ({
    source: "Twitch",
    id,
    name,
    url: images.url_1x,
  }))
}
