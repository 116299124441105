import { BrowserRouter as Router } from "react-router-dom"
import Content from "./components/Content"
import { MobileContextProvider } from "./components/contexts/MobileContext"
import { OrientationProvider } from "./components/contexts/OrientationContext"
import { TokenContextProvider } from "./components/contexts/TokenContext"
import UserProfileProvider from "./components/contexts/UserProfileContext"

import "@radix-ui/themes/styles.css"
import "./App.css"
import { AlertVisibilityProvider } from "./components/contexts/AlertVisibilityContext"

function App() {
  return (
    <Router>
      <TokenContextProvider>
        <UserProfileProvider>
          <MobileContextProvider>
            <AlertVisibilityProvider>
              <OrientationProvider>
                <Content />
              </OrientationProvider>
            </AlertVisibilityProvider>
          </MobileContextProvider>
        </UserProfileProvider>
      </TokenContextProvider>
    </Router>
  )
}

export default App
