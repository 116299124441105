import { styled } from "styled-components"
import { media } from "../../styled.utils"
import BaseButton from "../Button.styled"
import Container from "../Container.styled"
import LoginButtonWithAuth from "../../../ui/button/LoginButton"

function MassiveCTA() {
  return (
    <Wrapper>
      <Container $relative>
        <Title>Free alerts, multichat, and chat games!</Title>

        <BackgroundImage src="/assets/logo512.png" alt="ChatRPG logo" />

        <LoginButtonWithAuth buttonText="Sign me up now!"/>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  position: relative;
  padding: calc(var(--space-2xl) * 1.1) 0;
  background-color: #eff7ff;
`

const Title = styled.h2`
  position: relative;
  font-size: var(--step-3);
  font-weight: 600;
  margin-bottom: 1rem;
  z-index: 1;
`

const BackgroundImage = styled.img`
  position: absolute;
  bottom: -2rem;
  right: 1rem;
  width: calc(var(--space-4xl) * 1.1);
  opacity: 0.75;

  ${media.lg`
    width: calc(var(--space-4xl) * 1.4);
  `}
`

const CTAButton = styled(BaseButton)`
  border-radius: 0.5rem;
  background-color: #f6b246;
  font-size: calc(var(--step-0) * 0.95);
  font-weight: 600;
  font-style: normal;
`

export default MassiveCTA
