import { styled } from "styled-components"

const BaseButton = styled.button.attrs({ type: "button" })`
  padding: 0.875rem 1.375rem;
  border-style: none;
  border-radius: 0.5rem;
  background-color: #f6b246;
`

export default BaseButton
