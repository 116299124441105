import { Callout } from "@radix-ui/themes"
import { IconType } from "react-icons"
import { RxInfoCircled } from "react-icons/rx"

interface CalloutProps extends Callout.RootProps {
  text: string
  icon?: IconType
}

function InfoBar({ text, icon: Icon, ...props }: CalloutProps) {
  return (
    <Callout.Root {...props}>
      <Callout.Icon>{Icon ? <Icon /> : <RxInfoCircled />}</Callout.Icon>

      <Callout.Text>{text}</Callout.Text>
    </Callout.Root>
  )
}

export default InfoBar
