import { Form, FormControl, FormField, FormLabel } from "@radix-ui/react-form"
import { Dialog, Flex, TextField, VisuallyHidden } from "@radix-ui/themes"
import { FormEvent, MouseEvent, useState } from "react"
import { FaCheck } from "react-icons/fa"
import { useToken } from "../../contexts/TokenContext"
import { useUserProfile } from "../../contexts/UserProfileContext"
import ActionButton from "../../ui/ActionButton"
import InfoBar from "../../ui/Callout"
import { checkUsernameAvailability, confirmUsername } from "./UsernameAPI"

import "./UsernamePromptBox.css"

// TODO: Centralized environment file
if (!process.env.REACT_APP_SERVER_URL) throw new Error("Server URL missing.")
const SERVER_URL = process.env.REACT_APP_SERVER_URL

function UsernamePrompt() {
  const { token, setToken } = useToken()
  const { profile, profileFetched, setProfile } = useUserProfile()

  const [isLoading, setIsLoading] = useState({ check: false, confirm: false })
  const [username, setUsername] = useState("")
  const [checkedUsernames, setCheckedUsernames] = useState<
    Record<string, boolean>
  >({})

  const handleCheck = async (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()

    setIsLoading((prev) => ({ ...prev, check: true }))
    try {
      const data = await checkUsernameAvailability(username, SERVER_URL)

      setCheckedUsernames((prev) => ({ ...prev, [username]: data.isAvailable }))
    } catch (error) {
      console.error("Error during username check:", error)
    } finally {
      setIsLoading((prev) => ({ ...prev, check: false }))
    }
  }

  const handleConfirm = async (event: FormEvent) => {
    event.preventDefault()

    if (!token) throw new Error("Access token missing.")

    setIsLoading((prev) => ({ ...prev, confirm: true }))
    try {
      const data = await confirmUsername(username, token, SERVER_URL)

      setToken(data.token)
      setProfile((prev) =>
        prev ? { ...prev, chatrpg_username: data.sanitizedUsername } : null
      )
    } catch (error) {
      console.error("Error during username confirmation:", error)
    } finally {
      setIsLoading((prev) => ({ ...prev, confirm: false }))
    }
  }

  const confirmEnabled = checkedUsernames[username]
  const checkDisabled =
    !username ||
    checkedUsernames[username] === false ||
    confirmEnabled ||
    isLoading.check

  return (
    <Dialog.Root
      open={Boolean(token && profileFetched && !profile?.chatrpg_username)}
    >
      <Dialog.Content maxWidth="25rem">
        <Dialog.Title>Choose a username</Dialog.Title>

        {/* <Dialog.Description size="2" mb="4">
          This will also be your username when you upgrade your profile to a
          streamer profile.
        </Dialog.Description> */}

        <Form
          onSubmit={(e) => {
            e.preventDefault()
          }}
        >
          <FormField name="username">
            <FormLabel asChild>
              <VisuallyHidden>Name</VisuallyHidden>
            </FormLabel>

            <FormControl asChild>
              <TextField.Root
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.currentTarget.value)}
              >
                {checkedUsernames[username] && (
                  <TextField.Slot side="right">
                    <FaCheck height="16" width="16" color="green" />
                  </TextField.Slot>
                )}
              </TextField.Root>
            </FormControl>
          </FormField>
        </Form>

        <InfoBar
          text="This will also serve your username when you upgrade your profile to a
          streamer profile."
          size="1"
          mt="3"
        />

        <Flex gap="3" mt="4" justify="end">
          <ActionButton
            // variant="surface"
            color="green"
            onClick={handleCheck}
            loading={isLoading.check}
            disabled={checkDisabled}
          >
            Check
          </ActionButton>

          <Dialog.Close>
            <ActionButton
              disabled={
                isLoading.confirm || {
                  status: !confirmEnabled,
                  content:
                    "Please check if the username doesn't already exist first.",
                }
              }
              loading={isLoading.confirm}
              onClick={handleConfirm}
            >
              Confirm
            </ActionButton>
          </Dialog.Close>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  )
}

export default UsernamePrompt
