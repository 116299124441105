import { useState } from 'react';

export function useAuthModule() {
    const [isAuthModuleOpen, setAuthModuleOpen] = useState(false);
  
    const handleLoginButtonClick = () => {
      setAuthModuleOpen(true);
    };
    
    const closeAuthModule = () => {
      console.log("Closing auth module");
      setAuthModuleOpen(false);
    };
  
    return {
      isAuthModuleOpen,
      handleLoginButtonClick,
      closeAuthModule,
    };
  }
  