// usernameAPI.ts

export const checkUsernameAvailability = async (username: string, serverUrl: string) => {
    const response = await fetch(`${serverUrl}/username/check-username`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username })
    });
  
    if (response.status === 409 || response.status === 400) {
      return { isAvailable: false, status: response.status };
    }
  
    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.status}`);
    }
  
    const data = await response.json();
    return { ...data, status: response.status };
  };
  
  
  export const confirmUsername = async (username: string, token: string, serverUrl: string) => {
    const response = await fetch(`${serverUrl}/username/confirm-username`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ username })
    });
  
    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.status}`);
    }
  
    return await response.json();
  };
  