// src/store/reducers/loadingReducer.ts
export const START_LOADING = 'START_LOADING';
export const STOP_LOADING = 'STOP_LOADING';

type LoadingState = boolean;

const initialState: LoadingState = false;

const loadingReducer = (state = initialState, action: { type: string }): LoadingState => {
  switch (action.type) {
    case 'START_LOADING':
      return true;
    case 'STOP_LOADING':
      return false;
    default:
      return state;
  }
};

export default loadingReducer;
