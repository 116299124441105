import { Box, Flex } from "@radix-ui/themes"
import { useSelector } from "react-redux"
import { getAllExtensionJWT } from "../../../store/slices/Auth"
import { useStreamerProfile } from "../../contexts/StreamerProfileContext"
import { useToken } from "../../contexts/TokenContext"
import SendChatButton from "./SendChatButton"

import "./ChatBottomBar.css"
import { RootState } from "../../../store"
import ExtensionPlaceholder from "../../extensions/ExtensionPlaceholder"
import { useAlertVisibility } from "../../contexts/AlertVisibilityContext"
import { useUserProfile } from "../../contexts/UserProfileContext"

function BottomBar() {
  const { token } = useToken();
  const { streamerProfile } = useStreamerProfile();
  const userStreamerProfile = useSelector((state: RootState) => state.userStreamerProfile);
  const { profile } = useUserProfile();
  const isEditMode = useSelector((state: RootState) => state.editMode) // Access the editMode state
  const { isCollapsed, toggleCollapse } = useAlertVisibility(); // Use the context

  const getExtensionJWT = useSelector(getAllExtensionJWT)

  const showAlertButton = profile?.isStreamer &&
    profile?.chatrpg_id === userStreamerProfile?.chatrpg_id &&
    userStreamerProfile?.settings?.alertsButton;

  const streamerPointsExtension = streamerProfile.extensions.find(
    ({ type, enabled }) => type === "Points" && enabled
  )

  const jwtToken = streamerPointsExtension ? getExtensionJWT(streamerPointsExtension.clientId) : null;


  const shouldShowPoints = token && jwtToken && streamerPointsExtension;

  // if (shouldShowPoints && streamerProfile) {
  //   console.log("Rendering iframe for streamer points extension");
  // }

  return (
    <Flex justify="between" align="center" pl="1" pr="2" pb="2" pt="2">
      <Flex align="center" style={{ flex: 1 }}>
        {
          shouldShowPoints && streamerProfile ? (
            <Box maxHeight="2rem" pl="1" asChild>
              <iframe
                src={`${streamerPointsExtension.buildUrl
                  }?token=${getExtensionJWT(streamerPointsExtension.clientId)}`}
                className="embeddedProject"
                title="Embedded Page"
              // allowTransparency
              />
            </Box>
          ) : // Render nothing or a placeholder when profiles are not available
            null // or <ExtensionPlaceholder className="defaultSize" />
        }

        {isEditMode && <ExtensionPlaceholder className="defaultSize" />}
      </Flex>

      <div>
        <Flex justify="between" align="center" gap="2">
          {showAlertButton && (
            <button className="alertButton" onClick={toggleCollapse}>
              {isCollapsed ? "Alert History" : "Hide Alerts"}
            </button>
          )}
          <SendChatButton />
        </Flex>
      </div>
    </Flex>
  )
}

export default BottomBar
