// AlertVisibilityContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { useToken } from './TokenContext';
import axios from 'axios';

export interface IAlertVisibilityContextAlert {
  _id: string;
  topic: string;
  data: any;
  timestamp: string;
}

interface AlertVisibilityContextType {
  isCollapsed: boolean;
  toggleCollapse: () => void;
  alerts: IAlertVisibilityContextAlert[];
  addAlert: (newAlert: IAlertVisibilityContextAlert) => void; // Add this function to add new alerts
  // setAlerts: React.Dispatch<React.SetStateAction<Alert[]>>;
}

const defaultContext = {
  isCollapsed: true, // Default state
  toggleCollapse: () => { }, // Default function, does nothing
  alerts: [],
  addAlert: () => {},
  // setAlerts: () => { }, // Default function does nothing
};

const AlertVisibilityContext = createContext<AlertVisibilityContextType>(defaultContext);

export const useAlertVisibility = () => useContext(AlertVisibilityContext);

export const AlertVisibilityProvider = ({ children }) => {
  const { token } = useToken();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [alerts, setAlerts] = useState([]);

  useEffect(() => {
    const fetchAlerts = async () => {
      if (!token) return;

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_WEBSOCKET_SERVER_URL}/streamer/getalerts`,
          {},
          {
            headers: {
              'Authorization': `Bearer ${token}`,
            }
          }
        );
        setAlerts(response.data.events || []);
      } catch (err) {
        console.error("Error fetching alerts:", err);
      }
    };

    fetchAlerts();
  }, [token]);

  const addAlert = (newAlert: IAlertVisibilityContextAlert) => {
    setAlerts(prevAlerts => {
      const updatedAlerts = [...prevAlerts, newAlert]; // Append the new alert
      // Sort alerts by timestamp in descending order (newest first)
      updatedAlerts.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());
      // Remove the oldest alert if there are more than 10
      // updatedAlerts.sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());
      if (updatedAlerts.length > 10) {
        updatedAlerts.pop(); // Remove the last alert (oldest)
      }
      // if (updatedAlerts.length > 10) {
      //   updatedAlerts.shift(); // Remove the first alert (oldest)
      // }
      return updatedAlerts;
    });
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <AlertVisibilityContext.Provider value={{ isCollapsed, toggleCollapse, alerts, addAlert }}>
      {children}
    </AlertVisibilityContext.Provider>
  );
};
