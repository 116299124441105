import { Text } from "@radix-ui/themes"
import React from "react"
import styled from "styled-components"

interface ChatUsernameProps {
  username: string
  color: string
}

const ChatUsername: React.FC<ChatUsernameProps> = ({ username, color }) => (
  <Wrapper size="2" style={{ color }}>
    {username}
    {username ? <span>: </span> : null}
  </Wrapper>
)

const Wrapper = styled(Text)`
  font-weight: 100;
  font-family: "SF", sans-serif;
  margin-right: 3px;

  & > span {
    display: inline-block;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    color: white;
  }
`

export default ChatUsername
