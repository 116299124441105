import { FaCaretDown, FaTiktok, FaTwitch, FaYoutube } from "react-icons/fa6"
import { MdOutlineLanguage } from "react-icons/md"
import { RiKickFill } from "react-icons/ri"
import { styled } from "styled-components"
import { media } from "../styled.utils"
import Container from "./Container.styled"

const socialMediaLinks = [
  { Icon: FaTwitch, url: "twitch" },
  { Icon: FaYoutube, url: "youtube" },
  { Icon: FaTiktok, url: "tiktok" },
  { Icon: RiKickFill, url: "kick" },
]

const footerLinks = [
  {
    header: "ChatRPG",
    links: [
      { label: "About", url: "/about" },
      { label: "FAQ", url: "/faq" },
      { label: "Contact", url: "/contact" },
    ],
  },
  { header: "Community", links: [{ label: "Discord", url: "" }] },
]

function Footer() {
  return (
    <Wrapper>
      <Container>
        <Content>
          <Branding>
            <Brand>
              <img src="/assets/UI/Images/logo192.png" alt="ChatRPG Logo" />
              <p>
                Chat<span>RPG</span>
              </p>
            </Brand>

            <p>Community and Chat is everything</p>

            {/* <SocialMediaLinks>
              {socialMediaLinks.map(({ Icon, url }) => (
                <li key={url}>
                  <Icon />
                </li>
              ))}
            </SocialMediaLinks> */}
          </Branding>

          <Links>
            {footerLinks.map(({ header, links }) => (
              <div key={header}>
                <p>{header}</p>

                <ul>
                  {links.map(({ label, url }) => (
                    <li key={label}>
                      <a href={url}>{label}</a>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </Links>
        </Content>

        <Copyright>
          <p>Copyright © ChatRPG. All Rights Reserved.</p>

          <div>
            <a href="https://chatrpg.com/terms-of-service">Terms of Service</a>
            <a href="https://chatrpg.com/privacy-policy">Privacy Policy</a>

            <LanguageSwitcher>
              <MdOutlineLanguage /> English
              <FaCaretDown />
            </LanguageSwitcher>
          </div>
        </Copyright>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.footer`
  padding: var(--space-xl) 0;
  background-color: #0d1f2d;

  * {
    color: white;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  gap: var(--space-4xl);

  ${media.lg`
    gap: calc(var(--space-4xl) * 1.6);
    padding-right: 12rem;
    flex-direction: row;
  `}
`

const Links = styled.div`
  display: flex;
  flex-grow: 1;
  gap: 2rem;

  & > div {
    flex-basis: 0;
    flex-grow: 1;
    font-size: var(--step-0);

    ${media.lg`
      font-size: var(--step--1);
    `}
  }

  & > div > p {
    margin-bottom: 0.5rem;
    font-weight: 600;
  }

  & ul > :not([hidden]) ~ :not([hidden]) {
    --space-y: 0.125rem;
    margin-top: var(--space-y);
    margin-bottom: var(--space-y);
  }
`

const Branding = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  ${media.lg`
    align-items: start;
  `}

  & > p {
    font-size: var(--step-0);

    ${media.lg`
      font-size: var(--step--1);
    `}
  }
`

const Brand = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.75rem;
  margin-bottom: 0.5rem;

  img {
    max-width: 2.125rem;
  }

  p {
    font-family: "Protest Strike", "Segoe UI", Tahoma, Geneva, Verdana,
      sans-serif;
    font-size: calc(var(--step-1) * 0.95);
    font-weight: 100;
    padding-right: 0.5rem;

    ${media.lg`
      font-size: calc(var(--step-1) * 0.95);
    `}
  }

  p > span {
    color: #f7b339;
  }
`

const SocialMediaLinks = styled.ul`
  display: flex;
  gap: 0.75rem;
  margin-top: 1rem;
  font-size: 1.5rem;
`

const Copyright = styled.div`
  margin-top: 4rem;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;
  gap: var(--space-s);
  text-align: center;
  font-size: calc(var(--step--1) * 0.9);

  ${media.lg`
    flex-direction: row;
  `}

  & > p,
  a {
    min-width: fit-content;
    color: #ffffffd3;
    font-weight: 300;
  }

  & > div {
    display: flex;
    align-items: center;
    gap: var(--space-l);

    ${media.lg`
      padding-right: 1.375rem;
    `}
  }
`

const LanguageSwitcher = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`

export default Footer
